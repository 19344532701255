/* React modules */

/* Our modules */
import GamblingApi from 'modules/gambling/gambling.api';
import {
  CasinoGame,
  LeaderBoard,
  GamblingFilter,
  GamesParams,
  CasinoSection,
  CasinoSectionsWithGamesResponse,
  CasinoGamesResponse,
  CasinoSectionsResponse,
  GameTypesResponse,
  AmusnetJackpotData,
  AmusnetJackpot,
} from 'modules/gambling/gambling.types';
import { FeedMessageType } from 'modules/feed/feed.api';
import feedStore from 'modules/feed/feed.store';
import loaderStore from 'components/Loader/loader.store';
import GamblingFilters from 'modules/gambling/gambling.filters';
import userStore from 'modules/user/user.store';
import authStore from 'modules/auth/auth.store';
import { isNumber, logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable, runInAction } from 'mobx';

// Hide promo tags from listing in all tags
const tagMaxAmount = 'Max Amount';
const tagLastPlayed = 'Most Played Game';

class GamblingStore {
  lastPlayed: CasinoGame[] = [];
  games: CasinoGamesResponse = [];
  gamesPerSection: CasinoSectionsWithGamesResponse = [];
  dynamicSections: any[] = [];
  gameUrl: string = '';
  hasMore: boolean = true;
  gameOfDay: CasinoGame | undefined;
  biggestWin: CasinoGame[] = [];
  favouriteGames: any[] = [];
  public gamesSections: CasinoSectionsResponse = [];
  public providers: {
    name: string;
    value: string;
  }[] = [];
  public leaderBoards: LeaderBoard[] = [];
  public gameTypes: {
    name: string;
    value: string;
  }[] = [];
  public filters: GamblingFilters;
  isLive: boolean = false;
  api: GamblingApi;

  pagination: {
    total: number;
    offset: number;
    limit: number;
  } = {
    offset: 0,
    limit: 48,
    total: 0,
  };

  amusnetJackpot: AmusnetJackpotData = {
    currentLevelI: 0,
    currentLevelII: 0,
    currentLevelIII: 0,
    currentLevelIV: 0,
  };

  constructor() {
    this.api = new GamblingApi();
    this.filters = new GamblingFilters();
    makeAutoObservable(this);

    feedStore.registerHandler(
      FeedMessageType.AMUSNET_JACKPOT,
      this.setAmusnetJackpot
    );
  }

  setAmusnetJackpot = (data: AmusnetJackpot) => {
    const { jackpot_data } = data || {}; // Double check if 'jackpot_data' or 'jackpot_eq_data' should be used

    this.amusnetJackpot = jackpot_data || {
      currentLevelI: 0,
      currentLevelII: 0,
      currentLevelIII: 0,
      currentLevelIV: 0,
    };
  };

  setIsLive = (isLive: boolean) => {
    this.isLive = isLive;
  };

  initGambling = () => {
    this.getGames();
    this.getSections();
    this.getDynamicSections();
    this.getProviders();
    this.getGameTypes();
    this.getGameOfDay();
    this.getLeaderBoard();
  };

  getGames = async () => {
    loaderStore.addLoader('gambling');
    this.hasMore = true;
    try {
      this.pagination.offset = 0;
      const { data } = await this.api.getGames(this.getParams());
      if (data.length < this.pagination.limit) {
        this.hasMore = false;
      }
      runInAction(() => {
        this.pagination = {
          total: data.length,
          offset: this.pagination.offset + this.pagination.limit,
          limit: this.pagination.limit,
        };
        this.games = data;
      });
    } catch (exception) {
      logger('GamblingStore -> getGames -> exception', exception);
    } finally {
      loaderStore.removeLoader('gambling');
    }
  };

  getQuickGames = async () => {
    return this.api.getQuickGames();
  };

  getGameUrl = async (gameId: number, token: string) => {
    loaderStore.addLoader('play-game');
    try {
      const response = await this.api.getGameUrl(gameId, token);
      runInAction(() => {
        this.gameUrl = response.data.game_url;
      });

      return response.data;
    } catch (exception) {
      this.gameUrl = '';

      logger('GamblingStore -> getGameUrl -> exception', exception);

      return Promise.reject(exception);
    } finally {
      loaderStore.removeLoader('play-game');
    }
  };

  getQuickGameUrl = async (gameId: number, token: string) => {
    try {
      const response = await this.api.getGameUrl(gameId, token);
      return response.data.game_url;
    } catch (exception) {
      logger('GamblingStore -> getQuickGameUrl -> exception', exception);

      return Promise.reject(exception);
    }
  };

  getTryQuickGameUrl = async (gameId: number) => {
    try {
      const response = await this.api.tryGameUrl(gameId);
      return response.data.game_url;
    } catch (exception) {
      logger('GamblingStore -> getTryQuickGameUrl -> exception', exception);

      return Promise.reject(exception);
    }
  };

  tryGameUrl = async (gameId: number) => {
    loaderStore.addLoader('play-game');
    try {
      const response = await this.api.tryGameUrl(gameId);
      runInAction(() => {
        this.gameUrl = response.data.game_url;
      });

      return response.data;
    } catch (exception) {
      logger('GamblingStore -> tryGameUrl -> exception', exception);

      return Promise.reject(exception);
    } finally {
      loaderStore.removeLoader('play-game');
    }
  };

  loadMore = async () => {
    if (this.hasMore) {
      try {
        const { data } = await this.api.getGames(this.getParams());

        if (data.length < this.pagination.limit) this.hasMore = false;

        runInAction(() => {
          this.pagination = {
            total: data.length + this.pagination.total,
            offset: this.pagination.offset + this.pagination.limit,
            limit: this.pagination.limit,
          };

          this.games = [...this.games, ...this.sortGames(data)];
        });
      } catch (exception) {
        logger('GamblingStore -> loadMore -> exception', exception);
      }
    }
  };

  getProviders = async () => {
    loaderStore.addLoader('games-providers');
    try {
      const { data } = await this.api.getGameProviders(this.isLive);
      runInAction(() => {
        this.providers = data.map((o: any) => ({
          name: o.provider,
          value: o.provider,
        }));
      });
      this.providers.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    } catch (exception) {
      logger('GamblingStore -> getProviders -> exception', exception);
    } finally {
      loaderStore.removeLoader('games-providers');
    }
  };

  getGameTypes = async () => {
    loaderStore.addLoader('games-types');
    try {
      const { data } = await this.api.getGameTypes(this.isLive);
      runInAction(() => {
        this.gameTypes = this.formatFilters(data);
      });
    } catch (exception) {
      logger('GamblingStore -> getGameTypes -> exception', exception);
    } finally {
      loaderStore.removeLoader('games-types');
    }
  };

  getSections = async () => {
    loaderStore.addLoader('games-sections');
    try {
      const { data } = await this.api.getSections();
      runInAction(() => {
        // Filter promo and mobile tags. Mobile tags have position number over 100
        this.gamesSections = data.filter(
          (tag: any) =>
            tag.tag_name !== tagMaxAmount &&
            tag.tag_name !== tagLastPlayed &&
            tag.position < 100
        );
      });
    } catch (exception) {
      logger('GamblingStore -> getSections -> exception', exception);
    } finally {
      loaderStore.removeLoader('games-sections');
    }
  };

  getGameOfDay = async () => {
    loaderStore.addLoader('game-of-day');
    try {
      const { data } = await this.api.getGameOfDay();
      runInAction(() => {
        this.gameOfDay = data;
      });
    } catch (exception) {
      logger('GamblingStore -> getGameOfDay -> exception', exception);
    } finally {
      loaderStore.removeLoader('game-of-day');
    }
  };

  filterPromoSections = () => {
    this.gamesPerSection = this.gamesPerSection.filter(
      (tag: any) =>
        tag.tag_name !== tagMaxAmount && tag.tag_name !== tagLastPlayed
    );
  };

  sortSections = () => {
    this.gamesPerSection.sort(
      (prevTag: any, nextTag: any) => prevTag.position - nextTag.position
    );
  };

  getSectionGames = async () => {
    loaderStore.addLoader('gambling');
    try {
      const { data } = await this.api.getSectionsGames(this.isMobile);
      runInAction(() => {
        this.gamesPerSection = data;
        this.filterPromoSections();
        this.sortSections();
        this.setGamesPerSection();
      });
    } catch (exception) {
      logger('GamblingStore -> getSectionGames -> exception', exception);
    } finally {
      loaderStore.removeLoader('gambling');
    }
  };

  getDynamicSections = async () => {
    loaderStore.addLoader('dynamic-sections');
    try {
      const { data } = await this.api.getDynamicSections();
      runInAction(() => {
        this.dynamicSections = data;
        this.lastPlayed = this.dynamicSections.filter(
          (games) => games.tag_name === 'Most Played Game'
        )[0]?.games;
        this.biggestWin = this.dynamicSections.filter(
          (games) => games.tag_name === 'Max Amount'
        )[0]?.games;
      });
    } catch (exception) {
      logger('GamblingStore -> getDynamicSections -> exception', exception);
    } finally {
      loaderStore.removeLoader('dynamic-sections');
    }
  };

  getFavouriteGames = async () => {
    try {
      const { data } = await this.api.getFavouriteGames();
      runInAction(() => {
        this.favouriteGames = data;
        if (this.favouriteGames.length < 6) {
          this.favouriteGames = [
            ...data,
            ...data,
            ...data,
            ...data,
            ...data,
            ...data,
          ];
        }
      });
    } catch (exception) {
      logger('GamblingStore -> getFavouriteGames -> exception', exception);
    }
  };

  setFavouriteGame = async (gameId: number) => {
    try {
      await this.api.setFavouriteGame(gameId);
    } catch (exception) {
      // TODO: handle error
      logger('GamblingStore -> setFavouriteGame -> exception', exception);
    }
  };

  removeFavouriteGame = async (gameId: number) => {
    try {
      await this.api.removeFavouriteGame(gameId);
    } catch (exception) {
      // TODO: handle error
      logger('GamblingStore -> removeFavouriteGame -> exception', exception);
    }
  };

  getLeaderBoard = async () => {
    loaderStore.addLoader('leaderBoard');
    var userId = null;
    if (authStore.isLoggedIn) {
      userId = Number(localStorage.getItem('id'));
    }

    try {
      const { data } = await this.api.getLeaderBoard(userId);

      runInAction(() => {
        this.leaderBoards = data;
      });
    } finally {
      loaderStore.removeLoader('leaderBoard');
    }
  };

  setFreeCasino = async (active: boolean) => {
    try {
      await this.api.setFreeCasino(active);

      userStore.initUser();
    } catch (exception) {
      // TODO: handle error
      logger('GamblingStore -> setFreeCasino -> exception', exception);
    }
  };

  searchGames = (filters: any) => {
    this.filters.setFilters(filters);
    this.getGames();
  };

  toggleSystemFilter = (filter: GamblingFilter) => {
    this.filters.toggleSystemFilter(filter);
    this.getGames();
  };

  toggleGameTypeFilter = (filter: GamblingFilter) => {
    this.filters.toggleGameTypeFilter(filter);
    this.getGames();
  };

  addSystemFilter = (filter: GamblingFilter) => {
    this.filters.addSystemFilter(filter);
    this.getGames();
  };

  toggleSectionFilter = (filter: CasinoSection) => {
    if (filter.tag_name === 'favourite_games') {
      this.filters.toggleSectionFilter(filter);
      this.games = this.favouriteGames;
    } else {
      this.filters.toggleSectionFilter(filter);
      this.getGames();
    }
  };

  setHasMore = (hasMore: boolean) => {
    this.hasMore = hasMore;
  };

  get hasMoreGames() {
    const { total } = this.pagination;
    return this.games.length < total;
  }

  get isMobile(): boolean {
    return this.filters.$mobile;
  }

  get getTitle(): string {
    const section = this.gamesSections.find((section) =>
      this.filters.$sectionFilters.has(section.id)
    );
    return section ? section.tag_name : 'GAMES';
  }

  get getSectionId() {
    const section = this.gamesSections.find((section) =>
      this.filters.$sectionFilters.has(section.id)
    );
    return section ? section.id : '/';
  }

  setGamesPerSection() {
    // On the casino page when we have less than 8 games per section, carousel breaks down,
    // this function is used to add games if there are less than 8 games to show.
    this.gamesPerSection.forEach((game: any, i: number) => {
      if (game.games.length === 1) {
        this.gamesPerSection[i].games = [
          ...game.games,
          ...game.games,
          ...game.games,
          ...game.games,
          ...game.games,
          ...game.games,
          ...game.games,
          ...game.games,
        ];
      } else if (game.games.length <= 7) {
        this.gamesPerSection[i].games = [
          ...game.games,
          ...game.games,
          ...game.games,
          ...game.games,
        ];
      }
    });
  }

  getParams = (): GamesParams => {
    const { limit, offset } = this.pagination;

    return {
      is_live: this.isLive,
      filters: this.filters.activeFilters,
      pagination: { limit, offset },
    };
  };

  sortGames(data: CasinoGame[]) {
    return (data || []).sort((a, b) => {
      if (
        a.position &&
        isNumber(a.position) &&
        b.position &&
        isNumber(b.position)
      ) {
        if (+a.position > +b.position) {
          return 1;
        }

        if (+a.position < +b.position) {
          return -1;
        }
      }

      return 0;
    });
  }

  formatFilters(data: GameTypesResponse) {
    return (Object.entries(data) || []).map(([key, value]) => ({
      name: value,
      value: key,
    }));
  }

  getAmusnetJackpotData = async () => {
    try {
      const { data } = await this.api.getAmusnetJackpotData();

      if (data && data.data) {
        const { jackpot_data } = data.data; // // Double check if 'jackpot_data' or 'jackpot_eq_data' should be used

        if (jackpot_data) {
          this.amusnetJackpot = jackpot_data;
        }
      }
    } catch (exception) {
      logger('GamblingStore -> getAmusnetJackpotData -> exception', exception);
    }
  };
}

export default new GamblingStore();
