/* React modules */

/* Our modules */
import { TicketStatus, TicketStatusGroups } from 'modules/ticket/ticket.types';

/* 3rd Party modules */

export const TICKET_FILTERS: any = {
  [TicketStatusGroups.ALL]: [
    TicketStatus.NOT_RESOLVED,
    TicketStatus.APPROVING,
    TicketStatus.MANUAL_CHANGED,
    TicketStatus.FAILED,
    TicketStatus.PASSED,
    TicketStatus.PAYED_OUT,
  ],
  [TicketStatusGroups.ACTIVE]: [TicketStatus.NOT_RESOLVED],
  [TicketStatusGroups.APPROVING]: [
    TicketStatus.APPROVING,
    TicketStatus.MANUAL_CHANGED,
  ],
  [TicketStatusGroups.FAILED]: [TicketStatus.FAILED],
  [TicketStatusGroups.PASSED]: [TicketStatus.PASSED, TicketStatus.PAYED_OUT],
};

export const TICKET_STATUS_OPTIONS: any = (t: any) => [
  {
    label: t('globals.all'),
    value: TicketStatusGroups.ALL,
  },
  {
    label: t('slips.active'),
    value: TicketStatusGroups.ACTIVE,
  },
  {
    label: t('slips.approving'),
    value: TicketStatusGroups.APPROVING,
  },
  {
    label: t('slips.passed'),
    value: TicketStatusGroups.PASSED,
  },
  {
    label: t('slips.failed'),
    value: TicketStatusGroups.FAILED,
  },
];

export const FREE_BET_RULES = {
  minEvents: 3,
  minTotalOdd: 3,
};
