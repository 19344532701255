/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import {
  User,
  UserStatus,
  UserStatuses,
  ResponsibleGamblingRequest,
} from 'modules/user/user.models';
import { PokerErrors, PokerError } from 'modules/payment/payment.types';
import {
  WalletType,
  BonusType,
  WalletTypes,
} from 'modules/wallet/wallet.types';
import UserApi from 'modules/user/user.api';
import CreditDebitFilters from 'modules/user/credit-debit.filters';
import TransactionFilters from 'modules/user/transaction.filters';
import sportsStore from 'modules/sports/store/sports.store';
import loaderStore from 'components/Loader/loader.store';
import Wallet from 'modules/wallet';
import { isNumber, logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

class UserStore {
  user: User | null = null;
  hasPromoBetWallet: boolean = false;
  creditDebitFilters: CreditDebitFilters;
  transactionFilters: TransactionFilters;
  api: UserApi;
  wallets: Wallet[] = [];
  freeCasinoStatus: boolean = false;
  pokerBalance: number = 0;

  constructor() {
    this.api = new UserApi();
    this.creditDebitFilters = new CreditDebitFilters();
    this.transactionFilters = new TransactionFilters(this.api);

    makeAutoObservable(this);
  }

  initUser = () => {
    this.getUserData();
  };

  handleGetPokerBalanceError = (error: string | PokerError) => {
    switch (error) {
      case PokerErrors.INVALID_USER:
        toast.error(i18n.t('errors.invalid-user'));
        break;
      case PokerErrors.POKER_NOT_RESPONDING:
        toast.error(i18n.t('errors.poker-not-responding'));
        break;
      case PokerErrors.POKER_SUCCESS_FALSE:
        toast.error(i18n.t('errors.poker-success-false'));
        break;
      default:
        if (sportsStore.isPokerEnabled)
          toast.error(i18n.t('errors.system-error'));
        break;
    }
  };

  getPokerBalance = async () => {
    try {
      const {
        data: { available },
      } = await this.api.getPokerBalance();

      if (isNumber(available)) {
        this.pokerBalance = available;
      }
    } catch (exception: any) {
      this.handleGetPokerBalanceError(exception.data.detail);

      logger('UserStore -> getPokerBalance -> exception', exception);

      return Promise.reject(exception);
    }
  };

  setPokerDeposit = async (amount: number) => {
    try {
      loaderStore.setActive(true);

      const {
        data: { available },
      } = await this.api.setPokerDeposit(amount);

      if (isNumber(available)) {
        this.pokerBalance = available;
        this.initUser();
      }
    } catch (exception: any) {
      logger('UserStore -> setPokerDeposit -> exception', exception);

      return Promise.reject(exception);
    } finally {
      loaderStore.setActive(false);
    }
  };

  setPokerWithdrawal = async (amount: number) => {
    try {
      loaderStore.setActive(true);

      const {
        data: { available },
      } = await this.api.setPokerWithdrawal(amount);

      if (isNumber(available)) {
        this.pokerBalance = available;
        this.initUser();
      }
    } catch (exception: any) {
      logger('UserStore -> setPokerWithdrawal -> exception', exception);

      return Promise.reject(exception);
    } finally {
      loaderStore.setActive(false);
    }
  };

  getUserData = async () => {
    const { data } = await this.api.getUser();

    this.setUser(data.user);
    this.initWallets(data);
    this.freeCasinoStatus = data.free_casino_wallet.active;

    return data;
  };

  initWallets = (userData: any) => {
    const { wallet, free_bet_wallet, free_casino_wallet, promo_bet_wallet } =
      userData;

    this.wallets = [];

    this.wallets.push(
      new Wallet(WalletTypes.STANDARD, {
        ...wallet,
        cannotWithdraw: wallet.cannot_withdraw,
      })
    );

    if (wallet.withdrawal_reserved || wallet.bank_withdrawal_reserved) {
      this.wallets.push(
        new Wallet(WalletTypes.RESERVED_FUNDS, {
          available:
            wallet.withdrawal_reserved + wallet.bank_withdrawal_reserved,
        })
      );
    }

    this.wallets.push(new Wallet(WalletTypes.PROMO, free_bet_wallet));
    this.wallets.push(new Wallet(WalletTypes.FREE_CASINO, free_casino_wallet));

    if (wallet.free_casino_unlock) {
      const { bonus_amount, bonus_to_unlock } = wallet.free_casino_unlock;

      this.wallets.push(
        new Wallet(WalletTypes.FREE_CASINO_TO_UNLOCK, {
          available: bonus_amount,
          amountToUnlock: bonus_to_unlock,
        })
      );
    }

    if (promo_bet_wallet) {
      let isTimeInvalid = false;

      const { promo_bet_valid_to } = promo_bet_wallet;

      if (promo_bet_valid_to) {
        isTimeInvalid = new Date(promo_bet_valid_to) < new Date();
      }

      if (!isTimeInvalid) {
        this.wallets.push(
          new Wallet(WalletTypes.PROMO_BET_BONUS, promo_bet_wallet)
        );

        this.hasPromoBetWallet = true;
      } else {
        this.hasPromoBetWallet = false;
      }
    } else {
      this.hasPromoBetWallet = false;
    }

    if (wallet.promo_bet_bonus) {
      const { bonus_amount, bonus_to_unlock } = wallet.promo_bet_bonus;

      this.wallets.push(
        new Wallet(WalletTypes.PROMO_BET_BONUS_TO_UNLOCK, {
          available: bonus_amount,
          amountToUnlock: bonus_to_unlock,
        })
      );
    }

    if (wallet.free_bet_bonus) {
      const { bonus_amount, bonus_to_unlock } = wallet.free_bet_bonus;

      this.wallets.push(
        new Wallet(WalletTypes.FREE_BET_BONUS, {
          available: bonus_amount,
          amountToUnlock: bonus_to_unlock,
        })
      );
    }

    if (wallet.casino_bonus) {
      const { bonus_amount, bonus_to_unlock } = wallet.casino_bonus;

      this.wallets.push(
        new Wallet(WalletTypes.CASINO_BONUS, {
          available: bonus_amount,
          amountToUnlock: bonus_to_unlock,
        })
      );
    }
  };

  getWalletBalance = (walletType: WalletType) => {
    const wallet = this.wallets.find((wallet) => wallet.type === walletType);
    return wallet?.available || 0;
  };

  get isActiveFreeCasino() {
    return this.freeCasinoStatus;
  }

  get standardWallet() {
    return this.wallets.find((wallet) => wallet.type === WalletTypes.STANDARD);
  }

  get promoWallet() {
    return this.wallets.find((wallet) => wallet.type === WalletTypes.PROMO);
  }

  get reservedFunds() {
    return this.wallets.find(
      (wallet) => wallet.type === WalletTypes.RESERVED_FUNDS
    );
  }

  get freeBetBonus() {
    return this.wallets.find(
      (wallet) => wallet.type === WalletTypes.FREE_BET_BONUS
    );
  }

  get casinoBonus() {
    return this.wallets.find(
      (wallet) => wallet.type === WalletTypes.CASINO_BONUS
    );
  }

  get shouldActivateBonus(): boolean {
    return (
      !!this.user &&
      !!this.user.eligible_for_bonus &&
      !!this.user.eligible_bonus_amount
    );
  }

  get isActivated(): boolean {
    return this.user?.status === UserStatuses.ACTIVATED;
  }

  get isValidated(): boolean {
    return this.user?.status === UserStatuses.VALIDATED;
  }

  setUser = (user: User) => {
    this.user = user;
    localStorage.setItem('id', String(user.id));
  };

  clearUser = () => {
    this.user = null;
    this.wallets = [];
  };

  updateUserStatus = (status: UserStatus) => {
    if (this.user) {
      this.user = { ...this.user, status };
    }
  };

  uploadUserDocuments = (payload: any) => {
    loaderStore.addLoader('verification');
    try {
      return this.api.upoloadDocuments(payload);
    } catch (exception: any) {
      logger('UserStore -> uploadUserDocuments -> exception', exception);
    } finally {
      loaderStore.removeLoader('verification');
    }
  };

  updateUserData = async (payload: any) => {
    try {
      const response = await this.api.updateUser(payload);

      if (response.status === 200) {
        this.getUserData();
        return true;
      }
    } catch (exception: any) {
      logger('UserStore -> updateUserData -> exception', exception);

      return Promise.reject(exception?.data);
    }
  };

  selectBonus = async (bonusType: BonusType) => {
    try {
      await this.api.selectBonus(bonusType);
      this.initUser();
      return true;
    } catch (exception: any) {
      logger('UserStore -> selectBonus -> exception', exception);

      return Promise.reject(exception);
    }
  };

  getResponsibleGamblingLimits = async () => {
    let result = null;

    try {
      const { data } = await this.api.getResponsibleGamblingLimits();

      if (data) {
        result = data;
      }
    } catch (exception: any) {
      logger(
        'UserStore -> getResponsibleGamblingLimits -> exception',
        exception
      );
    }

    return result;
  };

  setResponsibleGamblingLimits = async (
    requestData: ResponsibleGamblingRequest
  ) => {
    let result = false;

    try {
      loaderStore.setActive(true);

      const { data } = await this.api.setResponsibleGamblingLimits(requestData);

      if (data) {
        result = data.delay;
      }
    } catch (exception: any) {
      logger(
        'UserStore -> setResponsibleGamblingLimits -> exception',
        exception
      );
    } finally {
      loaderStore.setActive(false);
    }

    return result;
  };

  setResponsibleGamblingPause = async (dateAndTime: string) => {
    let result = null;

    try {
      const { data } = await this.api.setResponsibleGamblingPause(dateAndTime);

      if (data && data.blocked_actions) {
        result = data.blocked_actions;

        toast.success(i18n.t('verification.data-saved'));
      }
    } catch (exception: any) {
      logger(
        'UserStore -> setResponsibleGamblingPause -> exception',
        exception
      );

      const { detail } = exception.data || {};

      toast.error(
        i18n.t(
          detail === 'ERR_USER_BLOCKED_ALREADY'
            ? 'errors.ERR_USER_BLOCKED_ALREADY'
            : 'errors.system-error'
        )
      );
    }

    return result;
  };
}

export default new UserStore();
