/* React modules */

/* Our modules */
import AuthStore from 'modules/auth/auth.store';
import SportsStore from 'modules/sports/store/sports.store';
import OfferStore from 'modules/offer/store/offer.store';
import OfferFilters from 'modules/offer/store/offer.filters';
import OfferSort from 'modules/offer/store/offer.sort';
import OfferEvent from 'modules/offer/store/offer.event';
import MarketSwitcher from 'modules/offer/store/market-switcher';
import TicketBuilderStore from 'modules/ticket/store/ticket-builder';
import UserTicketsStore from 'modules/ticket/store/user-tickets';
import TicketValidatorStore from 'modules/ticket/store/ticket-validator';
import FeedStore from 'modules/feed/feed.store';
import UserStore from 'modules/user/user.store';
import MarketStore from 'modules/user/market.store';
import LoaderStore from 'components/Loader/loader.store';
import OverlayStore from 'libs/overlay-store';
import GamblingStore from 'modules/gambling/gambling.store';
import BettingPlacesStore from 'modules/betting-places/betting-places.store';
import CashDropStore from 'modules/user/cash-drop.store';

/* 3rd Party modules */

export default class RootStore {
  authStore;
  sportsStore;
  offerStore;
  offerFilters;
  offerSort;
  offerEvent;
  ticketsStore;
  ticketBuilder;
  ticketValidator;
  feedStore;
  loaderStore;
  userStore;
  marketStore;
  marketSwitcher;
  overlayStore;
  gamblingStore;
  bettingPlacesStore;
  cashDropStore;

  constructor() {
    this.authStore = AuthStore;
    this.sportsStore = SportsStore;
    this.offerStore = OfferStore;
    this.offerFilters = OfferFilters;
    this.offerSort = OfferSort;
    this.offerEvent = OfferEvent;
    this.ticketsStore = UserTicketsStore;
    this.ticketBuilder = TicketBuilderStore;
    this.ticketValidator = TicketValidatorStore;
    this.feedStore = FeedStore;
    this.loaderStore = LoaderStore;
    this.userStore = UserStore;
    this.marketStore = MarketStore;
    this.marketSwitcher = MarketSwitcher;
    this.overlayStore = OverlayStore;
    this.gamblingStore = GamblingStore;
    this.bettingPlacesStore = BettingPlacesStore;
    this.cashDropStore = CashDropStore;
  }
}
