/* React modules */

/* Our modules */
import authStore from 'modules/auth/auth.store';
import { getSocketUrl } from 'libs/urlBuilder';

/* 3rd Party modules */
import { Centrifuge } from 'centrifuge';

export enum EventMessageActions {
  CREATE = 'C',
  DELETE = 'D',
  ENABLE = 'EN',
  DISABLE = 'DI',
}

export enum FeedMessageType {
  EVENT = 'event',
  DISABLED_SPORT = 'disable_sport',
  DISABLED_ALL = 'disable_all',
  COMPETITION = 'competition',
  AMUSNET_JACKPOT = 'jackpot',
}

const FEED_SOCKET_URL = 'ws/wsfeed';

const USER_LIVE_CHANNEL = 'web:live';
const USER_PREMATCH_CHANNEL = 'web:prematch';
const GUEST_LIVE_CHANNEL = 'guest:live';
const GUEST_PREMATCH_CHANNEL = 'guest:prematch';
export const AMUSNET_JACKPOT_CHANNEL = 'jackpot:amusnet';

class FeedApi {
  private socket: any = null;
  private liveSocket: any = null;
  private prematchSocket: any = null;
  private eventSocket: any = null;

  connect(onMessage: (type: FeedMessageType, message: any) => void) {
    /*
    const socketUrl = getSocketUrl(FEED_SOCKET_URL);
    this.socket = new Centrifuge(socketUrl);
    this.socket.connect();
    */
    this.initSocket();

    this.connectLive(this.socket, onMessage);
    this.connectPrematch(this.socket, onMessage);
  }

  initSocket() {
    const socketUrl = getSocketUrl(FEED_SOCKET_URL);
    this.socket = new Centrifuge(socketUrl);
    this.socket.connect();
  }

  connectLive(
    socket: any,
    onMessage: (type: FeedMessageType, message: any) => void
  ) {
    this.liveSocket = socket.newSubscription(
      authStore.isLoggedIn ? USER_LIVE_CHANNEL : GUEST_LIVE_CHANNEL
    );
    this.liveSocket.subscribe();
    this.listen(this.liveSocket, onMessage);
  }

  connectPrematch(
    socket: any,
    onMessage: (type: FeedMessageType, message: any) => void
  ) {
    this.prematchSocket = socket.newSubscription(
      authStore.isLoggedIn ? USER_PREMATCH_CHANNEL : GUEST_PREMATCH_CHANNEL
    );
    this.prematchSocket.subscribe();
    this.listen(this.prematchSocket, onMessage);
  }

  connectEvent(
    onMessage: (type: FeedMessageType, message: any) => void,
    eventId: string
  ) {
    const sub = this.socket.getSubscription(`web:event:${eventId}`);
    if (sub) {
      this.eventSocket.subscribe();
    } else {
      this.eventSocket = this.socket.newSubscription(`web:event:${eventId}`);
      this.eventSocket.subscribe();
    }
    this.listen(this.eventSocket, onMessage);
  }

  disconnectEvent(eventId: string) {
    const sub = this.socket.getSubscription(`web:event:${eventId}`);
    if (sub) sub.unsubscribe();
  }

  connectAmusnetJackpot(
    onMessage: (type: FeedMessageType, message: any) => void
  ) {
    // If user enters directly to Casino page, we should start 'wsfeed' first
    if (!this.socket) {
      this.initSocket();
    }

    if (this.socket) {
      let currentSubscription = this.socket.getSubscription(
        AMUSNET_JACKPOT_CHANNEL
      );

      if (!currentSubscription) {
        currentSubscription = this.socket.newSubscription(
          AMUSNET_JACKPOT_CHANNEL
        );
      }

      currentSubscription.subscribe();
      this.listen(currentSubscription, onMessage);
    }
  }

  disconnectAmusnetJackpot() {
    if (this.socket) {
      const currentSubscription = this.socket.getSubscription(
        AMUSNET_JACKPOT_CHANNEL
      );

      if (currentSubscription) {
        currentSubscription.unsubscribe();
        currentSubscription.removeAllListeners();
      }
    }
  }

  listen(socket: any, onMessage: (t: FeedMessageType, message: any) => void) {
    socket.on('publication', function (ctx: any) {
      const { type, message } = ctx.data;
      onMessage(type, message);
    });

    socket.on('disconnected', function (ctx: any) {
      if (socket) {
        socket.disconnect();
      }
    });
  }

  disconnect = () => {
    if (this.socket) {
      this.socket.disconnect();
    }
  };
}

export default FeedApi;
