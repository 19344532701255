/* React modules */

/* Our modules */
import { PokerErrors, PokerError } from 'modules/payment/payment.types';
import useStores from 'common/hooks/useStores';
import QuickGamesService from 'modules/quick-games/quick-games.service';
import Login from 'modules/auth/ui/Login';
import VerificationModal from 'modules/user/ui/VerificationModal';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export function useExternalLink() {
  const { t } = useTranslation();

  const { authStore, userStore, loaderStore, overlayStore } = useStores();

  const service = new QuickGamesService();

  async function handleExternalLink(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null,
    label: string,
    url: string | null
  ) {
    if (event) {
      event.preventDefault();
    }

    const handleGetPokerLaunchURLError = (error: string | PokerError) => {
      switch (error) {
        case PokerErrors.INVALID_USER:
          toast.error(t('errors.invalid-user'));
          break;
        case PokerErrors.USER_NOT_VALIDATED:
          toast.error(t('errors.user-not-validated'));
          break;
        case PokerErrors.POKER_NOT_RESPONDING:
          toast.error(t('errors.poker-not-responding'));
          break;
        case PokerErrors.POKER_SUCCESS_FALSE:
          toast.error(t('errors.poker-success-false'));
          break;
        default:
          toast.error(t('errors.system-error'));
          break;
      }
    };

    // Is link for Poker
    if (label && /poker/gi.test(label)) {
      // Is user authenticated (and also activated, as it can only login successfully)
      if (authStore.isLoggedIn) {
        // Is user validated
        if (userStore.isValidated) {
          // Retrieve the URL for Poker from game provider (with its appropriate token) and open it in new browser tab
          try {
            loaderStore.setActive(true);

            await service.getPokerLaunchURL().then((response) => {
              if (response) {
                window.open(response, '_blank', 'noreferrer');
              }
            });
          } catch (exception: any) {
            handleGetPokerLaunchURLError(exception.data.detail);

            logger(
              'useExternalLink -> handleExternalLink -> exception',
              exception
            );
          } finally {
            loaderStore.setActive(false);
          }
        } else {
          // Or open Verification modal for non-validated user
          overlayStore.openModal(<VerificationModal />, { width: 'small' });
        }
      } else {
        // Or open Login modal for non-authenticated user
        const modalContent = (
          <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
        );

        overlayStore.openModal(modalContent, {
          className: 'login-modal',
          width: 'small',
        });
      }
    } else if (url) {
      // If the link is any other than Poker, then just open it in new browser tab
      window.open(url, '_blank', 'noreferrer');
    }
  }

  return { handleExternalLink };
}
