interface CasinoGameBase {
  // Reference: services2/casino2/base/serializer/base.py
  id: number;
  game_name: string;
  game_system: string;
  provider: string;
  position: number;
  external_id: string;
  game_type: string;
  image: string;
  image_preview: string;
  image_filled: string;
  image_background: string;
  system: string;
  mobile: boolean;
  desktop: boolean;
  brand_info: {
    // Model of 'brand_info' property is defined by Greentube
    Id: number;
    BrandDisplayName: string;
    BrandIdentifier: string;
    ClientIdentifier: string;
    IsActive: boolean;
  };
  payout_rate: number;
  language_code: string;
  bonus_game: boolean;
  free_spins: boolean;
  lines: string;
  reels: string;
  min_bet: number;
  max_bet: number;
  play_for_fun: boolean;
  is_quick_game: boolean;
  game_of_day: boolean;
}

type CasinoGameSize = 'small' | 'large';

interface CasinoGame extends CasinoGameBase {
  size: CasinoGameSize;
}

interface GamblingFilter {
  name: string;
  value: string;
}

type CasinoGamesResponse = CasinoGame[]; // Reference: services2/casino2/base/api/platform.py

interface CasinoSectionGame {
  // Reference: services2/casino2/base/services/platform.py
  id: number;
  game_name: string;
  game_system: string;
  game_type: string;
  provider: string;
  image: string;
  external_id: string;
  image_preview: string;
  image_filled: string;
  image_background: string;
  play_for_fun: boolean;
}

interface CasinoSectionWithGames {
  // Reference: services2/casino2/base/services/platform.py
  id: number;
  tag_name: string;
  position: number | null;
  games: CasinoSectionGame[];
}

type CasinoSection = Omit<CasinoSectionWithGames, 'games'>;

type CasinoSectionsResponse = CasinoSection[]; // Reference: services2/casino2/base/api/platform.py

type CasinoSectionsWithGamesResponse = CasinoSectionWithGames[]; // Reference: services2/casino2/base/api/platform.py

type GameTypesResponse = string[]; // Reference: services2/casino2/base/api/platform.py

interface GamblingFilterParams {
  game_type?: Array<string>;
  provider?: Array<string>;
  mobile?: boolean;
}

interface TopLeaderBoard {
  bet: number;
  hands: number;
  id: string;
  last_hand: string;
  net: number;
  points: number;
  pos: number;
  username: string;
  win: number;
}

interface UserPosition {
  id: string;
  points: number;
  pos: number;
  username: string;
}

interface LeaderBoard {
  mobile_tag: number;
  top: TopLeaderBoard[];
  user_position: UserPosition;
  web_tag: number;
}

interface GamesParams {
  is_live: boolean;
  filters: GamblingFilterParams;
  pagination: {
    limit: number;
    offset: number;
  };
}

enum CasinoErrors {
  USER_BLOCKED = 'ERR_USER_BLOCKED',
}

type CasinoError = CasinoErrors.USER_BLOCKED;

interface AmusnetJackpotData {
  currentLevelI: number;
  currentLevelII: number;
  currentLevelIII: number;
  currentLevelIV: number;
}

interface AmusnetJackpot {
  jackpot_data: AmusnetJackpotData;
  jackpot_eq_data: AmusnetJackpotData;
}

export { CasinoErrors };

export type {
  CasinoGameSize,
  CasinoGame,
  GamblingFilter,
  CasinoGamesResponse,
  CasinoSectionGame,
  CasinoSectionWithGames,
  CasinoSection,
  CasinoSectionsResponse,
  CasinoSectionsWithGamesResponse,
  GameTypesResponse,
  GamblingFilterParams,
  LeaderBoard,
  GamesParams,
  CasinoError,
  AmusnetJackpotData,
  AmusnetJackpot,
};
