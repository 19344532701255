/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import feedStore from 'modules/feed/feed.store';
import sportsStore from 'modules/sports/store/sports.store';
import authStore from 'modules/auth/auth.store';
import userStore from 'modules/user/user.store';
import ticketStore from 'modules/ticket/store/user-tickets';
import offerFilters from 'modules/offer/store/offer.filters';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import NotificationsService from 'modules/notifications/notifications.service';
import SystemService from 'modules/system/system.service';
import oddFeedService from 'modules/sports/services/odd-feed.service';

/* 3rd Party modules */
import { autorun } from 'mobx';
import { toast } from 'react-toastify';

class AppService {
  notificationsService: NotificationsService | null = null;
  systemService: SystemService | null = null;

  constructor() {
    this.listenForLogin();
    this.listenForLogout();
    this.listenForConnectionChange();

    sportsStore.loadPokerConfiguration();
  }

  setup(): void {
    sportsStore.resetState();
    sportsStore.loadData();
    oddFeedService.feedOdds = new Map();
    feedStore.start();
    this.connectToSystemSocket();

    autorun(() => {
      if (sportsStore.sportsData) {
        offerFilters.onSportsLoad();
      }
    });
  }

  connectToSystemSocket(): void {
    const handlers = {
      validation_rules_update: () => ticketBuilder.onRulesChanged(),
    };

    this.systemService = new SystemService(handlers);
  }

  listenForLogin(): void {
    autorun(() => {
      if (authStore.isLoggedIn) {
        this.onLogin();
      }
    });
  }

  onLogin(): void {
    userStore.initUser();
    userStore.getPokerBalance();

    if (authStore.token) {
      const handlers = {
        ...ticketStore.getApprovalHandlers(),
      };

      this.notificationsService = new NotificationsService(
        handlers,
        authStore.token
      );
    } else {
      console.error(
        'Could not connect to notifications socket because token is not valid.'
      );
    }
  }

  listenForLogout(): void {
    autorun(() => {
      if (!authStore.isLoggedIn) {
        ticketStore.closeApprovalSocket();
        userStore.clearUser();
      }
    });
  }

  listenForConnectionChange(): void {
    window.addEventListener('offline', () => {
      feedStore.close();
      toast.error(i18n.t('errors.connection-offline'));
    });

    window.addEventListener('online', () => {
      toast.info(i18n.t('verification.connection-online'));
      this.setup();
    });
  }
}

export default new AppService();
