enum PaymentMethods {
  MONRI = 'monri',
}

type PaymentMethod = PaymentMethods.MONRI;

enum MonriResponseCodes {
  APPROVED = '0000',
  CARD_EXPIRED = '1001',
  CARD_SUSPICIOUS = '1002',
  CARD_SUSPENDED = '1003',
  CARD_STOLEN = '1004',
  CARD_LOST = '1005',
  CARD_NOT_FOUND = '1011',
  CARDHOLDER_NOT_FOUND = '1012',
  ACCOUNT_NOT_FOUNT = '1014',
  NOT_SUFFICIENT_FUNDS = '1016',
  TRANSACTION_DECLINED = '1050',
  INVALID_CARD_NUMBER = '1804',
  CARD_NOT_ACTIVE = '1806',
  INVALID_AMOUNT = '1810',
}

/* interface MonriDepositResponse {
  amount: number;
  authenticity_token: string;
  currency: string;
  language: string;
  ch_address: string;
  ch_city: string;
  ch_country: string;
  ch_email: string;
  ch_phone: string;
  ch_zip: string;
  ch_full_name: string;
  transaction_type: string;
  order_number: string;
  order_info: string;
  digest: string;
} */

enum PhysicalPayments {
  JATerminali,
  TABACCOSPRESS,
}

type PhysicalPayment =
  | PhysicalPayments.TABACCOSPRESS
  | PhysicalPayments.JATerminali;

enum PokerErrors {
  INVALID_USER = 'ERR_INVALID_USER',
  USER_NOT_VALIDATED = 'ERR_USER_NOT_VALIDATED',
  POKER_NOT_RESPONDING = 'ERR_POKER_NOT_RESPONDING',
  POKER_SUCCESS_FALSE = 'ERR_POKER_SUCCESS_FALSE',
  NOT_ENOUGH_FUNDS = 'ERR_NOT_ENOUGH_FUNDS',
  CANCEL_FUNDS = 'ERR_CANCEL_FUNDS',
}

type PokerError =
  | PokerErrors.INVALID_USER
  | PokerErrors.USER_NOT_VALIDATED
  | PokerErrors.POKER_NOT_RESPONDING
  | PokerErrors.POKER_SUCCESS_FALSE
  | PokerErrors.NOT_ENOUGH_FUNDS
  | PokerErrors.CANCEL_FUNDS;

enum WithdrawalErrors {
  NOT_ENOUGH_FUNDS = 'ERR_NOT_ENOUGH_FUNDS',
  USER_BLOCKED = 'ERR_USER_BLOCKED',
  USER_NOT_VALIDATED = 'ERR_USER_NOT_VALIDATED',
  NOT_FOUND = 'ERR_NOT_FOUND',
}

type WithdrawalError =
  | WithdrawalErrors.NOT_ENOUGH_FUNDS
  | WithdrawalErrors.USER_BLOCKED
  | WithdrawalErrors.USER_NOT_VALIDATED
  | WithdrawalErrors.NOT_FOUND;

/* enum ActiveWithdrawalsResponse {
  NO_ACTIVE_RESERVATIONS = 'ERR_NO_ACTIVE_WITHDRAWAL_RESERVATION',
} */

enum WithdrawalStatuses {
  PENDING = '__PENDING__',
  READY_FOR_WITHDRAWAL = '__READY_FOR_WITHDRAWAL__',
  APPROVED = '__APPROVED__',
}

type WithdrawalStatus =
  | WithdrawalStatuses.PENDING
  | WithdrawalStatuses.APPROVED
  | WithdrawalStatuses.READY_FOR_WITHDRAWAL;

interface ActiveWithdrawal {
  amount: number;
  betting_place_id: number;
  created_at: string;
  id: number;
  code: string;
  status: WithdrawalStatus;
}

enum PromoCodeActivationError {
  INVALID_CODE = 'ERR_INVALID_CODE',
  ALREADY_ACTIVATED = 'ERR_ALREADY_ACTIVATED',
  CODE_USED_UP = 'ERR_CODE_USED_UP',
  CODE_NOT_FOR_NEW_USERS = 'ERR_CODE_NOT_FOR_NEW_USERS',
  CODE_NOT_FOR_EXISTING_USERS = 'ERR_CODE_NOT_FOR_EXISTING_USERS',
}

type PromoCodeAmountType = 'free_bet' | 'free_casino' | 'both' | 'deposit';

interface ActivatePromoCodeResponse {
  amount: number;
  amount_fc: number;
  amount_type: PromoCodeAmountType;
}

export {
  PaymentMethods,
  MonriResponseCodes,
  PhysicalPayments,
  PokerErrors,
  WithdrawalErrors,
  WithdrawalStatuses,
  PromoCodeActivationError,
};

export type {
  PaymentMethod,
  PhysicalPayment,
  PokerError,
  WithdrawalError,
  ActiveWithdrawal,
  ActivatePromoCodeResponse,
};
