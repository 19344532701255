/* React modules */
import { useState } from 'react';

/* Our modules */
import {
  MAX_DEBIT_PHYSICAL,
  MIN_DEBIT_PHYSICAL,
} from 'modules/payment/payment.constants';
import {
  PhysicalPayment as PhysicalPaymentType,
  PhysicalPayments,
} from 'modules/payment/payment.types';
import CreditDebitBalance from 'modules/user/ui/UserCreditDebit/CreditDebitBalance';
import CreditDebitInfoBar from 'modules/user/ui/UserCreditDebit/CreditDebitInfoBar';
import { Select } from 'components';
import mPay from '../../../../../../../images/mPay.png';
import './PhysicalPayment.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

const PAYMENT_TYPES = [
  { label: 'J&A Terminali', value: PhysicalPayments.JATerminali },
  { label: 'TTABACCO S PRESS+', value: PhysicalPayments.TABACCOSPRESS },
];

const TabaccoPress = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t('payments.tabaccopress.instruction-1')}</p>

      <p className="mt-5">{t('payments.tabaccopress.instruction-2')}</p>

      <p className="mt-5">{t('payments.tabaccopress.deposit-steps')}</p>

      <ul className="mt-5">
        <li>1. {t('payments.tabaccopress.deposit-step-1')}</li>
        <li>2. {t('payments.tabaccopress.deposit-step-2')}</li>
        <li>3. {t('payments.tabaccopress.deposit-step-3')}</li>
      </ul>

      <p className="mt-5">{t('payments.tabaccopress.instruction-3')}</p>
      <p className="mt-5">{t('payments.tabaccopress.instruction-4')}</p>
    </div>
  );
};

const Terminals = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ul>
        <li>1. {t('payments.terminals.step-1')}</li>
        <li>2. {t('payments.terminals.step-2')}</li>
        <li>3. {t('payments.terminals.step-3')}</li>
        <li>3. {t('payments.terminals.step-3')}</li>
      </ul>

      <p className="mt-5">{t('payments.terminals.note')}</p>
    </div>
  );
};

const PhysicalPayment = () => {
  const [paymentType, setPaymentType] = useState<PhysicalPaymentType>(
    PAYMENT_TYPES[0].value
  );

  const onPaymentTypeChange = (type: any) => {
    setPaymentType(type.value);
  };

  return (
    <div className="physical-payment">
      <CreditDebitBalance />
      <div className="mt-16">
        <CreditDebitInfoBar
          min={MIN_DEBIT_PHYSICAL}
          max={MAX_DEBIT_PHYSICAL}
          isDebit={true}
        />
      </div>
      <div className="physical-payment__payment">
        <section className="d-flex align-items">
          <div>
            <Select
              className="mb-1 select"
              options={PAYMENT_TYPES}
              bg="sb-dark"
              size="sb-market"
              value={PAYMENT_TYPES.find((t) => t.value === paymentType)}
              shape="squared"
              onChange={onPaymentTypeChange}
            />
            <p className="mt-5">PAYSAFECARD</p>
          </div>
          <div className="ml-10">
            <img
              src={mPay}
              alt="mPay"
              className="w-100"
              data-testid="footer-thumbnail"
            />
          </div>
        </section>

        <section className="physical-payment__description mt-8">
          {paymentType === PhysicalPayments.TABACCOSPRESS && <TabaccoPress />}
          {paymentType === PhysicalPayments.JATerminali && <Terminals />}
        </section>
      </div>
    </div>
  );
};

export default PhysicalPayment;
