/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { currencyWithoutSymbol } from 'libs/currency-formatter';
import './AmusnetBanner.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

export const AmusnetBanner = observer(() => {
  const {
    feedStore: { subscribeAmusnetJackpot, unsubscribeAmusnetJackpot },
    gamblingStore: { amusnetJackpot, getAmusnetJackpotData },
  } = useStores();

  useEffect(() => {
    async function fetchAmusnetJackpotData() {
      await getAmusnetJackpotData();
    }

    fetchAmusnetJackpotData();

    subscribeAmusnetJackpot();

    return () => {
      unsubscribeAmusnetJackpot();
    };
  }, []);

  return (
    <div className="amusnet-banner pb-4">
      <div className="h-30 scalable-image jackpot-cards-logo" />

      <div className="h-62">
        <div className="h-50 xy-center scalable-image pik-box text-yellow-500">
          <span className="text-yellow-500">
            {currencyWithoutSymbol(amusnetJackpot.currentLevelIV)} &euro;
          </span>
        </div>

        <div className="h-45 d-flex boxes-2nd-line">
          <div className="w-33 h-100 xy-center scalable-image herc-box">
            <span className="text-yellow-500">
              {currencyWithoutSymbol(amusnetJackpot.currentLevelIII)} &euro;
            </span>
          </div>

          <div className="w-33 h-100 xy-center scalable-image karo-box">
            <span className="text-yellow-500">
              {currencyWithoutSymbol(amusnetJackpot.currentLevelII)} &euro;
            </span>
          </div>

          <div className="w-33 h-100 xy-center scalable-image tref-box">
            <span className="text-yellow-500">
              {currencyWithoutSymbol(amusnetJackpot.currentLevelI)} &euro;
            </span>
          </div>
        </div>
      </div>

      <div className="h-8 scalable-image amusnet-logo" />
    </div>
  );
});
