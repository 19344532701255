/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import * as ERROR_MESSAGES from 'modules/ticket/ticket.errors';
import { Button, Icon } from 'components';

/* 3rd Party modules */
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface TableActionsProps {
  placeBet: () => void;
  quickBet: () => void;
}

const TicketActions = observer(({ placeBet, quickBet }: TableActionsProps) => {
  const { t } = useTranslation();
  const { ticketBuilder, ticketValidator, overlayStore, feedStore } =
    useStores();
  const {
    acceptAllChanges,
    isQuickTicketDisabled,
    hasLiveEvents,
    changesAccepted,
    events,
    removeAll,
    slipEvents,
  } = ticketBuilder;

  const { ValidationRule } = ERROR_MESSAGES;

  const location = useLocation();

  const handleSubmit = () => {
    if (!changesAccepted) {
      acceptAllChanges();
    } else {
      placeBet();
    }
  };

  const removeAllSubscribtions = () => {
    // escape unsubscribing event if overlay with odds for that event is open
    const eventId = location.search.split('=')[1];
    slipEvents.forEach((event: any) => {
      if (event.id !== eventId) {
        feedStore.unsubscribeEvent(event.id);
      }
    });
  };

  const makeSlipEmpty = () => {
    removeAll();
    removeAllSubscribtions();
    ticketValidator.removeWarning(ValidationRule.MAX_WIN_AMOUNT);
  };

  // If Effect subscribes to something, the cleanup function should unsubscribe (https://react.dev/learn/synchronizing-with-effects#subscribing-to-events)
  useEffect(() => {
    function handlePlaceBetOnEnter(event: KeyboardEvent) {
      if (
        event.key === 'Enter' &&
        !ticketBuilder.isPlaceBetDisabled &&
        overlayStore.modalContent === null
      ) {
        handleSubmit();
      }
    }

    window.addEventListener('keypress', handlePlaceBetOnEnter);

    return () => window.removeEventListener('keypress', handlePlaceBetOnEnter);
  }, []);

  return (
    <div className="ticket__actions">
      <div className="ticket__actions--buttons">
        <Icon
          size="regular"
          container="warning"
          name="fastForward"
          onClick={quickBet}
          tooltip={hasLiveEvents ? t('slips.quick-ticket-live') : ''}
          tooltipPosition="bottom"
          isDisabled={isQuickTicketDisabled}
          className="quick-ticket-icon"
        />
        <Button
          className="place-bet"
          bg={changesAccepted ? 'success' : 'warning'}
          size="large"
          onClick={handleSubmit}
          disabled={ticketBuilder.isPlaceBetDisabled}
          rounded={false}
        >
          {changesAccepted ? t('slips.place-bet') : t('slips.accept-changes')}
        </Button>
        <Icon
          size="regular"
          container="danger"
          name="cancel"
          onClick={makeSlipEmpty}
          tooltip={events.length ? t('slips.delete-all') : ''}
          tooltipPosition="bottom"
          isDisabled={!events.length}
          className="cancel-ticket-icon"
        />
      </div>
    </div>
  );
});

export default TicketActions;
