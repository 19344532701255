/* React modules */
import { useState } from 'react';

/* Our modules */
import { PaymentMethod, PaymentMethods } from 'modules/payment/payment.types';
import {
  MAX_DEBIT_BANK,
  MIN_DEBIT_BANK,
} from 'modules/payment/payment.constants';
import CreditDebitBalance from 'modules/user/ui/UserCreditDebit/CreditDebitBalance';
import CreditDebitInfoBar from 'modules/user/ui/UserCreditDebit/CreditDebitInfoBar';
import CreditDebitAmountPicker from 'modules/user/ui/UserCreditDebit/CreditDebitAmountPicker';
import monriPayment from 'images/monri-payment.png';
import './CreditCard.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

export const footerThumnbails = [
  { src: monriPayment, alt: 'M Pay', link: 'http://monri.com/' },
];

export const amountOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '250', value: 250 },
  { label: '500', value: 500 },
];

interface CreditCardProps {
  onDepositInit: (amount: number, method: PaymentMethod) => void;
}

const CreditCard = ({ onDepositInit }: CreditCardProps) => {
  const { t } = useTranslation();
  const [depositInitiated, setDepositInitiated] = useState(false);
  const initDeposit = (amount: number) => {
    onDepositInit(amount, PaymentMethods.MONRI);
    setDepositInitiated(true);
  };

  return (
    <div className="credit-card">
      <CreditDebitBalance />
      <div className="mt-16">
        <CreditDebitInfoBar
          min={MIN_DEBIT_BANK}
          max={MAX_DEBIT_BANK}
          isDebit={true}
        />
      </div>
      <div className="mt-12">
        {!depositInitiated && (
          <CreditDebitAmountPicker
            onSubmit={initDeposit}
            buttonText={t('payments.pay')}
            min={MIN_DEBIT_BANK}
            max={MAX_DEBIT_BANK}
            amountOptions={amountOptions}
          />
        )}

        <form id="monri-payment-form" className="credit-card__init-payment">
          {depositInitiated && (
            <span className="mr-3">{t('payments.proceed-with-payment')}</span>
          )}
        </form>
      </div>

      <div className="d-flex justify-center align-items w-50 mx-auto">
        {footerThumnbails.map((thumbnail, index) =>
          thumbnail.link ? (
            <a
              key={`CreditCard-a-${index}`}
              href={thumbnail.link}
              target="blank"
            >
              <img
                src={thumbnail.src}
                alt={thumbnail.alt}
                className="mx-2"
                style={{ height: '30px' }}
                data-testid="footer-thumbnail"
              />
            </a>
          ) : (
            <img
              key={`CreditCard-img-${index}`}
              src={thumbnail.src}
              alt={thumbnail.alt}
              className="mx-2"
              style={{ height: '30px' }}
              data-testid="footer-thumbnail"
            />
          )
        )}
      </div>
    </div>
  );
};

export default CreditCard;
