export enum QuickGamesErrors {
  USER_BLOCKED = 'ERR_USER_BLOCKED',
}

export type QuickGamesError = QuickGamesErrors.USER_BLOCKED;

export enum QuickGamesOptions {
  LUCKY_SIX = 'luckysix',
  LUCKY_X = 'luckyx',
  GREYHOUND = 'greyhound',
  HORSE_RACES = 'vhorse',
  NEXT_SIX = 'nextsix',
  LIGHTNING = 'NVNVLightningLuckySix',
  AVIATOR = 'aviator',
  GOAL = 'goal',
  DICE = 'dice',
  MINI_ROULETTE = 'mini-roulette',
  PLINKO = 'plinko',
  KENO = 'keno',
  HILO = 'hi-lo',
  MINES = 'mines',
}

export type QuickGame =
  | QuickGamesOptions.LUCKY_SIX
  | QuickGamesOptions.LUCKY_X
  | QuickGamesOptions.GREYHOUND
  | QuickGamesOptions.HORSE_RACES
  | QuickGamesOptions.NEXT_SIX
  | QuickGamesOptions.LIGHTNING
  | QuickGamesOptions.AVIATOR
  | QuickGamesOptions.GOAL
  | QuickGamesOptions.DICE
  | QuickGamesOptions.MINI_ROULETTE
  | QuickGamesOptions.PLINKO
  | QuickGamesOptions.KENO
  | QuickGamesOptions.HILO
  | QuickGamesOptions.MINES;
