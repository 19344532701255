/* React modules */

/* Our modules */
import FeedApi, { FeedMessageType } from 'modules/feed/feed.api';

/* 3rd Party modules */

class LiveFeed {
  api: FeedApi;
  handlers: any = {};

  constructor() {
    this.api = new FeedApi();
  }

  registerHandler = (
    messageType: FeedMessageType,
    handler: (message: any) => void
  ) => {
    const handlers = this.handlers[messageType];

    if (handlers) {
      this.handlers[messageType] = [...handlers, handler];
    } else {
      this.handlers[messageType] = [handler];
    }
  };

  onMessage = (type: string, message: any) => {
    const handlers = this.handlers[type];

    if (handlers) {
      handlers.forEach((handler: any) => handler(message));
    }
  };

  start = () => {
    this.api.connect(this.onMessage);
  };

  close = () => {
    this.api.disconnect();
  };

  subscribeEvent = (eventId: string) => {
    this.api.connectEvent(this.onMessage, eventId);
  };

  unsubscribeEvent = (eventId: string) => {
    this.api.disconnectEvent(eventId);
  };

  subscribeAmusnetJackpot = () => {
    this.api.connectAmusnetJackpot(this.onMessage);
  };

  unsubscribeAmusnetJackpot = () => {
    this.api.disconnectAmusnetJackpot();
  };
}

export default new LiveFeed();
