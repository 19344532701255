/* React modules */
import { useContext } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import Odd from 'modules/sports/store/odd.store';
import {
  formatOddsByLimit,
  formatMarketsByFrames,
  formatMarketsByPlayer,
} from 'modules/offer/services/offer-helpers.service';
import SingleOdd from 'pages/sport/EventAllOddsOverlay/components/SingleOdd';
import { OddsMarketsContext } from 'pages/sport/EventAllOddsOverlay/EventAllOddsOverlay';
import EventMobile from 'modules/offer/ui/OfferMobile/EventMobile/EventMobile';
import {
  sortObj,
  findLowestDifference,
  sortByLowestDifference,
} from 'pages/sport/EventAllOddsOverlay/components/helpers';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const isDuplicateOutcome = (odd: Odd, allOdds: Odd[]) => {
  const oddIndex = allOdds.findIndex((o) => o.id === odd.id);
  const nextOdds = allOdds.slice(oddIndex);

  return nextOdds.some((o) => o.outcome?.id === odd.outcome?.id);
};

const OddsByMarket = observer(
  ({
    odds,
    name,
    englishName,
    albanianName,
    turkishName,
    russianName,
    ukrainianName,
    italianName,
    germanName,
    getName,
    hasLimit,
    isSnooker = false,
  }: any) => {
    const { i18n } = useTranslation();

    const getMarketNameTranslation = (lang: string) => {
      switch (lang) {
        case 'me':
          return name;
        case 'en':
          return englishName;
        case 'al':
          return albanianName;
        case 'tr':
          return turkishName;
        case 'ru':
          return russianName;
        case 'ua':
          return ukrainianName;
        case 'it':
          return italianName;
        case 'de':
          return germanName;
        default:
          return '';
      }
    };

    odds = odds.filter((o: any) => {
      const isDuplicate = !o.limit && isDuplicateOutcome(o, odds);
      return o.visible && (!isDuplicate || !o.isDisabled) && !o.isDisabled;
    });

    const { t } = useTranslation();

    const oddsByLimit = formatOddsByLimit(odds);
    Object.keys(oddsByLimit).forEach((key: any) => {
      oddsByLimit[key].sort((a: any, b: any) =>
        a.outcome.id > b.outcome.id ? 1 : -1
      );
    });

    const sortedOddsByLimits = sortObj(oddsByLimit);
    const { lowestDifferenceKey, targetKey } =
      findLowestDifference(sortedOddsByLimits);
    const sortedByLowestDifference = sortByLowestDifference(
      sortedOddsByLimits,
      lowestDifferenceKey,
      targetKey
    );

    const event = useContext(OddsMarketsContext);

    return (
      <>
        {event.type === EVENT_TYPE.LIVE && !isSnooker && (
          <div className="all-odds-overlay__market">
            {Object.keys(sortedByLowestDifference).map(
              (limit: any, index) =>
                sortedByLowestDifference[limit].some(
                  (o: any) => !o.isDisabled
                ) && (
                  <div key={`OddsByMarket-div-1-${index}`}>
                    <div className="all-odds-overlay__market-name">
                      {getMarketNameTranslation(i18n.language)}
                    </div>

                    <div className="all-odds-overlay__odds">
                      {+limit !== 0 && (
                        <div className="all-odds-overlay__odd-limit-wrapper">
                          <span className="mr-auto">
                            {t('sportsPage.limit')}
                          </span>
                          <span className="ml-auto">{limit}</span>
                        </div>
                      )}

                      {event.type === 'live' &&
                        sortedByLowestDifference[limit].map(
                          (odd: any, i: number) => (
                            <SingleOdd
                              key={`OddsByMarket-SingleOdd-1-${odd.market?.name}-${odd.outcome?.name}-${i}`}
                              odd={odd}
                              market={name}
                            />
                          )
                        )}
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        {isSnooker && (
          <>
            {Object.keys(sortedByLowestDifference).map(
              (limit: any, index) =>
                ((hasLimit && +limit > 0) ||
                  +limit < 0 ||
                  (!hasLimit && +limit === 0)) && (
                  <div
                    key={`OddsByMarket-div-2-${index}`}
                    className="all-odds-overlay__market"
                  >
                    <div className="all-odds-overlay__market-name">
                      {getMarketNameTranslation(i18n.language)}
                    </div>

                    <div className="all-odds-overlay__odds">
                      {+limit !== 0 && (
                        <div className="all-odds-overlay__odd-limit-wrapper">
                          <span className="mr-auto">
                            {t('sportsPage.limit')}
                          </span>
                          <span className="ml-auto">{limit}</span>
                        </div>
                      )}

                      {((hasLimit && +limit > 0) ||
                        +limit < 0 ||
                        (!hasLimit && +limit === 0)) &&
                        sortedByLowestDifference[limit].map(
                          (odd: any, i: number) => (
                            <SingleOdd
                              key={`OddsByMarket-SingleOdd-2-${odd.market?.name}-${odd.outcome?.name}-${i}`}
                              odd={odd}
                              market={name}
                            />
                          )
                        )}
                    </div>
                  </div>
                )
            )}
          </>
        )}

        {event.type !== EVENT_TYPE.LIVE && !isSnooker && (
          <div className="all-odds-overlay__market">
            {Object.keys(sortedByLowestDifference).map(
              (limit: any, index) =>
                sortedByLowestDifference[limit].some(
                  (o: any) => !o.isDisabled
                ) && (
                  <div key={`OddsByMarket-div-3-${index}`}>
                    <div className="all-odds-overlay__market-name">
                      {getName(i18n.language)}
                    </div>

                    <div className="all-odds-overlay__odds">
                      {+limit !== 0 && (
                        <div className="all-odds-overlay__odd-limit-wrapper">
                          <span className="mr-auto">
                            {t('sportsPage.limit')}
                          </span>
                          <span className="ml-auto">{limit}</span>
                        </div>
                      )}

                      {sortedByLowestDifference[limit].map(
                        (odd: any, i: number) =>
                          event.type !== 'live' && !limit.isSuspended ? (
                            <SingleOdd
                              key={`OddsByMarket-SingleOdd-3-${odd.market?.name}-${odd.outcome?.name}-${i}`}
                              odd={odd}
                              market={name}
                            />
                          ) : null
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        )}
      </>
    );
  }
);

const OddsMarkets = observer(() => {
  const { offerEvent } = useStores();
  const { t } = useTranslation();

  // parse markets for players single event overlay
  const event = offerEvent?.event;

  let markets: any = [];
  if (
    offerEvent.event?.type === EVENT_TYPE.SPECIAL ||
    offerEvent.event?.type === EVENT_TYPE.PLAYER
  ) {
    event?.odds.forEach((odd: any, i: number) => {
      // if (markets.length > 0 && markets.some((m: any) => m.market.id === odd.market.id)) return;
      if (markets.length > 0) return;
      markets.push(odd);
    });
  }

  const hasOdds = offerEvent.markets().some((m: any) => !!m.odds.length);

  if (!hasOdds && markets.length < 1)
    return <div className="text-center mt-12">{t('sportsPage.no-odds')}</div>;

  let snookerMarkets: any = [];
  if (offerEvent.event?.sportId === 28) {
    snookerMarkets = formatMarketsByFrames(offerEvent.markets());
  }

  const playerMarkets = formatMarketsByPlayer(offerEvent.markets());
  const playerMarketsIds = [626, 627, 628, 629, 630];

  return (
    <>
      {offerEvent.event?.type !== EVENT_TYPE.SPECIAL &&
        offerEvent.event?.type !== EVENT_TYPE.PLAYER &&
        offerEvent.event?.sportId !== 28 && (
          <div className="all-odds-overlay__odds-markets">
            {offerEvent
              .markets()
              .map(
                (market: any, index: number) =>
                  !playerMarketsIds.includes(market.id) &&
                  market.odds.length > 0 && (
                    <OddsByMarket
                      key={`OddsMarkets-OddsByMarket-1-${index}`}
                      {...market}
                      getName={market.getName.bind(market)}
                    />
                  )
              )}
          </div>
        )}

      {/* HANDLE PLAYER MARKETS */}
      {offerEvent.event?.type !== EVENT_TYPE.SPECIAL &&
        offerEvent.event?.type !== EVENT_TYPE.PLAYER &&
        offerEvent.event?.sportId !== 28 && (
          <div className="all-odds-overlay__odds-markets">
            {playerMarkets.map(
              (market: any, index: number) =>
                playerMarketsIds.includes(market.id) &&
                market.odds.length > 0 && (
                  <OddsByMarket
                    key={`OddsMarkets-OddsByMarket-2-${index}`}
                    {...market}
                    getName={market.getName.bind(market)}
                  />
                )
            )}
          </div>
        )}

      {/* HANDLE SNOOKER */}
      {offerEvent.event?.sportId === 28 && (
        <div className="all-odds-overlay__odds-markets">
          {snookerMarkets.map(
            (market: any, index: number) =>
              market.odds.length > 1 && (
                <OddsByMarket
                  key={`OddsMarkets-OddsByMarket-3-${index}`}
                  isSnooker={true}
                  {...market}
                />
              )
          )}
        </div>
      )}

      {offerEvent.event?.type === EVENT_TYPE.SPECIAL ||
        (offerEvent.event?.type === EVENT_TYPE.PLAYER &&
          offerEvent.event.sportId !== 28 && (
            <div>
              {markets.map((market: any, i: number) => (
                <div
                  key={`OddsMarkets-EventMobile-${market.market.id}`}
                  className="mt-2"
                >
                  <EventMobile
                    event={market.event}
                    rowIndex={i}
                    hasHeader={false}
                  />
                </div>
              ))}
            </div>
          ))}
    </>
  );
});

export default OddsMarkets;
