/* React modules */

/* Our modules */
import { GamesParams, AmusnetJackpot } from 'modules/gambling/gambling.types';
import { http } from 'app/axios-config';

/* 3rd Party modules */

class GamblingApi {
  getGames(params?: GamesParams) {
    let endpoint = '/casino2/base/games';
    if (params?.pagination?.limit) {
      endpoint = `/casino2/base/games`;
    }

    const payload = params
      ? {
          ...params.pagination,
          ...params.filters,
          ...(params.is_live ? { is_live: true } : {}),
        }
      : {};

    return http.post(endpoint, payload);
  }

  getQuickGames() {
    return http.post('/casino2/base/games', {
      is_quick_game: true,
      desktop: true,
    });
  }

  getSections() {
    let endpoint = '/casino2/base/sections';
    return http.get(endpoint);
  }

  getSectionsGames(isMobile: boolean) {
    let endpoint = '/casino2/base/sections/games';
    return http.get(endpoint, { params: { mobile: isMobile, is_live: false } });
  }

  getDynamicSections() {
    let endpoint = '/casino2/base/sections/dynamic';
    return http.get(endpoint);
  }

  getGameProviders(isLive: boolean) {
    const params = isLive ? { is_live: true } : {};
    return http.get('/casino2/base/providers', { params });
  }

  getGameTypes(isLive: boolean) {
    const params = isLive ? { is_live: true } : {};
    return http.get('/casino2/base/game-types', { params });
  }

  getGameOfDay() {
    let endpoint = '/casino2/base/get-daily-game';
    return http.get(endpoint);
  }

  getLeaderBoard(userId?: number | null) {
    let url = `/reports/leaderboards/`;
    if (userId) url += `?user_id=${userId}`;

    return http.get(url);
  }

  getFavouriteGames() {
    let url = `/casino2/base/favourite_games`;
    return http.get(url);
  }

  setFavouriteGame(gameId: number) {
    let url = `/casino2/base/favourite_games`;
    return http.post(url, { game_id: gameId });
  }

  removeFavouriteGame(gameId: number) {
    let url = `/casino2/base/favourite_games`;
    return http.delete(url, { data: { game_id: gameId } });
  }

  getGameUrl(game_id: number, token: string) {
    return http.get('/casino/get-game-link', {
      params: { game_id, token },
    });
  }

  tryGameUrl(game_id: number) {
    return http.get('/casino/try-game-link', {
      params: { game_id },
    });
  }

  setFreeCasino(isActive: boolean) {
    let url = `/user/platform/free_casino_set_status?active=${isActive}`;
    return http.put(url);
  }

  getAmusnetJackpotData() {
    return http.get<{ data: AmusnetJackpot }>('/casino/amusnet_jackpot_banner');
  }
}

export default GamblingApi;
