const en = {
  translation: {
    casino: {
      'all-games': 'ALL GAMES',
      award: 'Award',
      back: 'BACK',
      'biggest-wins': 'BIGGEST WINS',
      'casino-brands': 'CASINO BRANDS',
      categories: 'CATEGORIES',
      category: 'CATEGORY',
      close: 'CLOSE',
      demo: 'DEMO PLAY',
      'favourite-games': 'FAVOURITE GAMES',
      'game-of-week': 'GAME OF THE WEEK',
      'last-played': 'LAST PLAYED',
      'most-played': 'MOST PLAYED',
      play: 'PLAY',
      points: 'Points',
      provider: 'PROVIDER',
      sections: 'SECTIONS',
      'see-all': 'SEE ALL',
      'use-free-casino': 'Use free spin',
      user: 'User',
    },
    countries: {
      albania: 'Albania',
      'bosnia-and-herzegovina': 'Bosnia and Herzegovina',
      croatia: 'Croatia',
      kosovo: 'Kosovo',
      montenegro: 'Montenegro',
      'republic-of-north-macedonia': 'Republic of North Macedonia',
      russia: 'Russia',
      serbia: 'Serbia',
      turkey: 'Turkey',
      ukraine: 'Ukraine',
    },
    errorPage: {
      'reload-page': 'Please reload the page.',
      'something-went-wrong': 'Something went wrong.',
    },
    errors: {
      'activation-link-exp':
        'To complete the registration you need to activate your account. An activation link has been sent to your email address.',
      'another-order-exists': 'Another order already exists',
      'bad-password': 'Invalid password',
      'bet-not-possible': 'Currently not possible to bet on this event',
      'can-not-find-user': 'Unable to find user',
      'cancel-funds': 'Scheduled payment not found.',
      'cannot-add-more-than-one-digital-item':
        'Can not add more than one digital item in cart',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Can not buy casino bonus if you has one',
      'cannot-cancel-order': 'Can not cancel order',
      'cannot-order-mixed-items': 'Can not order mixed type of items',
      'cannot-order-more-than-one-digital-item':
        'Can not order more than one digital item',
      'choose-betting-place': 'Select the place to pick up the order',
      'connection-offline': 'Internet connection lost!',
      'credentials-error': 'Username or password is incorrect.',
      'data-error-reading': 'Error saving data. Please try again.',
      'email-exists': 'A user with this email address already exists.',
      'email-invalid': 'Invalid email',
      'err-cash-drop-already-collected':
        'Someone was faster than you. CashDrop has been used and is no longer available.',
      'err-cash-drop-already-collected-by-user': 'CashDrop already collected',
      'err-cash-drop-group-already-collected-by-user':
        'You cannot collect CashDrop from this group',
      'err-cash-drop-not-active':
        'CashDrop has expired and is no longer available',
      'err-cash-drop-not-found': 'CashDrop not found',
      'err-cash-drop-user-min-balance':
        'You need to have {{a}} EUR on balance to collect this drop',
      'err-cash-drop-user-min-bet':
        'You need to spend minimum of {{a}} EUR in last {{b}} hours to be able to collect CashDrop',
      ERR_BETTING_MACHINE_BLOCKED: 'This action is blocked',
      ERR_CANT_PAYOUT_BETTING_PLACE: 'Can not payout betting place',
      ERR_CANT_PAYOUT_MUNICIPALITY: 'Can not payout municipality',
      ERR_CASINO_BLOCKED: 'Casino games are blocked',
      ERR_DEPOSIT_BLOCKED: "The user's payment has been blocked",
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE:
        'Deposit blocked for betting machine',
      ERR_INVALID_GAME: 'Invalid game',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        'The ticket is no longer valid for payment. Please contact your operator.',
      ERR_USER_BLOCKED_ALREADY: 'Your account has already been blocked.',
      ERR_VIRTUAL_GAMES_BLOCKED: 'Virtual games are blocked',
      ERR_VOUCHER_CONFIG_NOT_FOUND: 'Voucher config not found',
      ERR_VOUCHER_EXPIRED: 'Voucher expired',
      ERR_VOUCHER_NOT_FOUND: 'Voucher not found',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT:
        'Voucher status not valid for payout',
      'error-starting-game': 'The game cannot be started',
      'error-unknown': 'Something went wrong. Please try again.',
      'field-invalid': 'Field invalid',
      'field-required': 'Required field',
      'fields-obligated': 'Fields indicated with * are required.',
      'invalid-system-slip': 'Invalid system slip',
      'invalid-user': 'User is not valid',
      'item-not-available': 'Item not available',
      'item-not-found': 'Item not found',
      'jmbg-max':
        'Personal identification number cannot contain more than 13 digits',
      'jmbg-min':
        'Personal identification number must contain at least 13 digits',
      'min-password': 'Password must contain a minimum of {{len}} characters.',
      'no-betting-place-id': 'Select a branch office',
      'no-events-for-copy': 'There are no events that can be copied',
      'no-items-in-order': 'There are no products in the order',
      'not-enough-points': 'Not Enough Points',
      'order-does-not-exist': 'Order does not exists',
      'passwords-dont-match': 'Passwords do not match.',
      'payout-error': 'An error with payout attempt has occurred.',
      'place-bet-error':
        'An error occured while trying to place bet. Please try again.',
      'poker-not-responding': 'The poker server is currently unavailable.',
      'poker-success-false': 'The poker server action has not been successful.',
      'print-error':
        'An error with printing attempt has occurred. If the ticket is not printed, contact the operator.',
      'print-not-operational': 'THE PRINTER IS NOT OPERATIONAL',
      'promo-code-invalid': 'The promo code is not valid.',
      'reservation-locked': 'Reserved funds are locked',
      'session-expired': 'The session has expired, please log in again.',
      'system-error': 'A system error has occurred. Please try again.',
      'ticket-changed': 'Your ticket has changed.',
      'ticket-denied': 'Ticket not approved.',
      'ticket-error-reading': 'Error reading ticket.',
      TICKET_NOT_FOUND: 'Ticket not found',
      TICKET_NOT_VALID_FOR_PAYOUT:
        'The ticket is no longer valid for payment. Please contact your operator.',
      'token-expired': 'The token has expired',
      'user-blocked-error':
        'Your account is suspended. Please contact customer support.',
      'user-exists': 'User already exists.',
      'user-not-active': 'User not activated. Please activate your account.',
      'user-not-validated': 'User not verified',
      'user-update-error': 'Error saving data. Please try again.',
      'username-exists': 'A user with this username already exists.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Can not combine antepost events on same slip',
      'withdrawal-canceled-or-paid':
        'Isplata je otkazana ili isplaćena. Molimo vas da osvežite stranicu.',
      'withdrawal-max-amount-exceeded':
        'Maksimalna dozvoljena isplata je {{a}}',
      'withdrawal-min-amount-exceeded': 'Minimalna dozvoljena isplata je {{a}}',
      'zero-item-quantity': 'Zero item quantity',
    },
    footer: {
      'about-us': 'About Us',
      'account-id': 'ACCOUNT ID',
      'all-rights-reserved': 'All rights reserved',
      'available-for-payment': 'Available for payment',
      aviator: 'Aviator',
      betting: 'Betting',
      bonuses: 'Bonuses',
      casino: 'Casino',
      'casino-live': 'Live Casino',
      contact: 'Contact',
      cookies: 'Cookies',
      description:
        'SBBet is the oldest sports betting operator in Montenegro established in 1997. Since the very beginning we have continuously been providing services of the highest level to our clients. Over the past 25 years, we have developed a brand that is primarily synonymous with timely and secure payouts to all our clients.  At SBbet, we strive together to continuously improve our services and our ultimate goal is to successfully anticipate our clients needs and maintain their satisfaction. SBbet.me is part of Sporting Group doo, Mediteranska 21, Budva, Montenegro and is regulated by the laws of Montenegro. We hold a license issued by the Games of Chance Administration: 01-424/24-742/5.',
      'download-list': 'Download Section',
      'follow-us': 'Follow us',
      'frequently-questions': 'FAQ',
      'game-rules': 'Game Rules',
      informations: 'Informations',
      'lucky-6': 'Lucky6',
      'pay-all': 'PAY ALL',
      'payment-to-web': 'Payment to WEB',
      'play-responsibly': 'Play responsibly',
      poker: 'Poker',
      privacy: 'Privacy',
      'promo-code': 'Promo Code',
      promotions: 'Promotions',
      'site-map': 'Site map',
      sport: 'Sport',
      'ticket-check': 'Ticket check',
      transactions: 'Transactions',
      'using-conditions': 'Terms of Use',
    },
    globals: {
      accept: 'ACCEPT',
      'accept-cookies':
        'This site uses cookies to improve services, in order to show you relevant content. If you continue to search the site, we believe you have agreed to the use of cookies.',
      account: 'Account',
      'account-type': 'Account type',
      activation: 'ACTIVATION',
      'add-to-cart': 'Add to cart',
      all: 'All',
      'amount-caps': 'AMOUNT',
      'app-idle-msg':
        'You have not been active for some time. Click proceed to get the latest offer.',
      approved: 'Approved',
      'betting-history': 'Betting History',
      'booking-an-order': 'Booking an order',
      braon: 'Brown',
      cancel: 'CANCEL',
      canceled: 'Canceled',
      cart: 'Cart',
      'cart-is-empty': 'Cart is empty',
      Cet: 'Thu',
      'choose-a-betting-place': 'Choose SBbet shop',
      'choose-a-city': 'Choose a city',
      'choose-order-place': 'Choose order place in selected SBBet office',
      'click-for-more-info': 'Click for more info',
      close: 'Close',
      'code-caps': 'CODE',
      collect: 'Collect',
      copy: 'Copy',
      'copy-ticket': 'Copy ticket',
      'customer-support': 'Customer support',
      date: 'Date',
      'date-caps': 'DATE',
      delivered: 'Delivered',
      'filter-by-date': 'Filter by date',
      free: 'Free',
      'id-caps': 'ID',
      immediately: 'Immediately',
      'installation-guide': 'Installation guide',
      loading: 'Loading...',
      'mark-all-as-read': 'Mark everything as read',
      'my-account': 'My account',
      'necessary-reload': 'Necessary to synchronize the offer',
      Ned: 'Sun',
      nepar: 'Odd',
      no: 'NO',
      'no-info-about-event': 'There no any info about event',
      notifications: 'Notifications',
      numbers: 'NUMBERS',
      'order-history': 'Order history',
      par: 'Even',
      pending: 'Pending',
      Pet: 'Fri',
      'pick-date': 'Select date',
      'place-order': 'Place order',
      Pon: 'Mon',
      proceed: 'Proceed',
      profile: 'Profile',
      'promo-code': 'Promo code',
      register: 'REGISTER',
      'remove-from-cart': 'Remove from cart',
      save: 'Save',
      'sbbet-promo-code': 'SBbet PROMO CODE',
      search: 'Search',
      'search-1': 'Search',
      'search-events': 'Search events',
      send: 'SEND',
      'send-1': 'SEND',
      Sre: 'Wed',
      statistics: 'STATISTICS',
      'status-caps': 'STATUS',
      step: 'Step',
      Sub: 'Sat',
      submit: 'SUBMIT',
      success: 'Success',
      'successfully-collected-cash-drop': 'Successfully collected cash drop',
      'ticket-copied': 'Ticket copied',
      time: 'Time',
      'transaction-history': 'Transaction History',
      type: 'Type',
      'type-caps': 'TYPE',
      'user-id-caps': 'USER ID',
      Uto: 'Tue',
      wallets: 'Wallets',
      welcome: 'Welcome!',
      yes: 'YES',
      zelena: 'Green',
      žuta: 'Yellow',
    },
    header: {
      betting: 'Betting',
      casino: 'Casino',
      'casino-live': 'Casino live',
      live: 'Live',
      'quick-games': 'Quick games',
    },
    home: {
      '100-spins': '100 SPINS',
      'be-in-the-game-with-app': 'BE IN THE GAME WITH THE SBBET APP',
      betting: 'BETTING',
      'betting-description':
        'The highest odds and the most diverse offer of games and specials await you.',
      'call-center': 'CALL CENTER',
      'casino-description': 'The most popular casino games bring big jackpots',
      'follow-us': 'FOLLOW US',
      'live-betting': 'LIVE BETTING',
      'live-betting-description':
        'Live betting is now twice as good, because the largest selection of live sports and a wide range of games await you at SBbet!',
      'place-bet': 'GET A BET',
      'promotions-description':
        'Find out everything about new promotions and news.',
      'quick-games-description':
        'The largest selection of virtual: numbers, football, racing and much more!',
      register: 'Sign up',
      'up-to-250': 'UP TO 250',
      'we-double-your-first-deposit': 'WE DOUBLE YOUR FIRST DEPOSIT',
    },
    language: {
      english: 'English',
      language: 'language',
      montenegrin: 'Montenegrin',
      'select-language': 'Select language',
    },
    login: {
      'forgot-password': 'I forgot my password',
      'forgot-password-caps': 'FORGOT YOUR PASSWORD?',
      'log-in': 'LOG IN',
      'log-out': 'Log out',
      login: 'LOG IN',
      logout: 'LOG OUT',
      'logout-1': 'LOG OUT',
      'logout-question': 'Are you sure you want to log out?',
      'sign-in': 'SIGN IN',
    },
    navigation: {
      aviator: 'AVIATOR',
      betting: 'BETTING',
      casino: 'CASINO',
      liveCasino: 'LIVE CASINO',
      lucky6: 'LUCKY6',
      numbers: 'NUMBERS',
      offer: 'OFFER',
      poker: 'POKER',
      promotions: 'PROMOTIONS',
      quickGames: 'FAST GAMES',
      sport: 'BETTING and LIVE',
    },
    payments: {
      'account-id-confirm': 'Confirm that your ACCOUNT ID is:',
      amount: 'Amount',
      'available-for-payment': 'Withdrawable balance',
      balance: 'Balance',
      'balance-after': 'Balance after',
      'balance-before': 'Balance before',
      'bonus-balance': 'Bonus balance',
      'cancel-withdrawal': 'Cancel withdrawal',
      'cancel-withdrawal-question':
        'Are you sure you wish to cancel the withdrawal?',
      'cancel-withdrawal-success': 'You have successfully canceled withdrawal.',
      'card-expired': 'The transaction was rejected. The card has expired.',
      'card-not-found': 'Card not found. Please try again.',
      'card-suspended':
        'The transaction was rejected. The card has been suspended.',
      commission: 'Commission',
      credit: 'Credit',
      'credit-card': 'Credit card',
      debit: 'Debit',
      'e-wallet': 'E-wallet',
      'funds-available-for-payment': 'Available funds',
      'funds-that-can-be-raised': 'WITHDRAWABLE BALANCE',
      'in-the-bank': 'BANK ACCOUNT',
      'in-the-bank-note':
        'If your name is not correct please contact our support team.',
      'in-the-office': 'AT SHOP',
      'invalid-amount': 'The transaction was rejected. Amount is invalid.',
      'invalid-card-number':
        'The transaction was rejected. Card number is invalid.',
      'maximum-credit': 'Maximum credit',
      'maximum-debit': 'Maximum debit',
      'minimum-credit': 'Minimum credit',
      'minimum-debit': 'Minimum debit',
      pay: 'PAY',
      'payment-default-error':
        'The transaction was rejected. Please try again.',
      'payment-destination': 'Payment destination',
      'payment-success': 'Payment made successfully.',
      'physical-payment': 'Cash deposit',
      'poker-withdrawal-success': 'The payment was successfully made.',
      'potential-payout': 'Potential payout:',
      'proceed-with-payment': 'Continue with payment by clicking the button',
      skrill: 'Skrill',
      tabaccopress: {
        'deposit-step-1':
          'Ask the seller at one of the Tobacco S Press points of sale to pay a deposit to your Sbbet account via payment terminals',
        'deposit-step-2':
          'It is necessary to tell the seller the ID number of your SBBet account and the amount you want to deposit.',
        'deposit-step-3':
          'The amount you paid will be available on your SBBet account usually within a few minutes from the moment of payment.',
        'deposit-steps': 'How to pay a deposit through payment terminals?',
        'instruction-1':
          'For terminals deposit you will need SBbet account ID number.',
        'instruction-2':
          'You can cash deposit via payment terminals at one of the 300+ Tobacco S Press points of sale',
        'instruction-3':
          'The minimum amount of payment through payment terminals is 1.00 EUR, and the maximum amount of payment per transaction is 500.00 EUR.',
        'instruction-4':
          'In case of an error, the time limit for reversing the transaction at the point of sale is 10 minutes (if the money from the account has not been spent).',
        'instruction-5':
          'See TOBACCO SPRESS facilities where you can pay a deposit at the following link.',
      },
      terminals: {
        note: 'NOTE: If for some reason the funds are not paid to your SBBet account, please contact the J&A service at 067/788-588 in time 9h-17h, and after 17h-21h and weekends 068/488-588.',
        'step-1': 'At the J&A terminal, select the SBbet logo',
        'step-2': 'Enter your account ID and click the "PAY" button',
        'step-3':
          'Insert the desired amount of money into the terminal, and the device will read and display how much money you have put.',
        'step-4':
          'By clicking on the "CONFIRM" button, you will successfully make the payment and receive a printed receipt for your transaction.',
      },
      'transaction-date': 'Transaction date',
      'transaction-deposit': 'Deposit',
      'transaction-id': 'Transaction ID',
      'transaction-type': 'Transaction type',
      'transaction-web-bet': 'Web bet',
      'transaction-web-bet-rollback': 'Web bet rollback',
      'transaction-web-casino-credit': 'Casino credit',
      'transaction-web-casino-debit': 'Casino debit',
      'transaction-web-casino-rollback': 'Casino rollback',
      'transaction-web-game-bet': 'Web game bet',
      'transaction-web-game-rollback': 'Web game rollback',
      'transaction-web-game-win': 'Web game win',
      'transaction-web-win': 'Web win',
      'transaction-withdraw': 'Withdrawal',
      transactions: 'Transactions',
      withdraw: 'WITHDRAW',
      'withdraw-on-the-bank': 'BANK WITHDRAWAL',
      'withdraw-on-the-betting-place': 'WITHDRAW AT SHOP',
      'withdrawal-pending': 'On approval',
      'withdrawal-ready': 'Funds ready to be collected',
      'withdrawal-reserved': 'Reserved withdrawal',
      'withdrawal-success': 'You have successfully created withdrawal request.',
      'your-transaction-has-been-confirmed':
        'Your transaction has been confirmed',
    },
    promoBanner: {
      aviator: 'Aviator',
      betting: 'Betting',
      blackjack: 'Blackjack',
      cashout: 'Cashout',
      'casino-tournament': 'Casino tournament',
      'defeat-goalkeeper-for-bonuses': 'Defeat goalkeeper for BONUSES',
      'double-first-payin': 'Double every first payin',
      'download-app': 'Download app',
      'fly-to-win': 'Fly to win',
      'happy-monday-bonus': 'Happy Monday Bonus',
      'lucky-6': 'Lucky 6',
      'netent-casino-games': 'NetEnt Casino Games',
      new: 'New',
      'pay-the-ticket-at-any-time': 'Pay ticket at any time',
      popular: 'Popular',
      promotion: 'Promotion',
      roulette: 'Roulette',
      'win-5000-jackpot': 'Win a 5.000 EUR jackpot',
      'win-apartment': 'Win an apartment in Podgorica',
      'win-iphone-13': 'Win an Iphone 13',
      'win-jackpot-up-to-5000': 'Win jackpot up to 5000 euros',
    },
    slips: {
      'accept-all-changes': 'Accept all changes',
      'accept-cashout': 'ACCEPT CASHOUT',
      'accept-changes': 'Accept changes',
      'accept-changes-after-place-bet': 'Accept changes after place bet',
      active: 'Active',
      'actual-winning-amount': 'Payout',
      'all-tickets': 'ALL TICKETS',
      approving: 'Waiting for approval',
      bet: 'Bet',
      'bet-type': 'Bet type',
      bonus: 'Bonus:',
      'cancel-cashout': 'Cancel cashout',
      cashout: 'CASHOUT',
      'cashout-accepted': 'Cashout accepted',
      'cashout-denied': 'Cashout denied successfully',
      'cashout-denied-timer':
        'Cashout was denied due to the expiration of the time for accepting the changes',
      changed: 'Changed',
      'confirm-ticket': 'CONFIRM BETSLIP',
      'delete-all': 'Delete All',
      'delete-ticket': 'Delete ticket',
      'delete-ticket-info': 'Do you want to delete this ticket',
      denied: 'Denied',
      'deny-cashout': 'REFUSE CASHOUT',
      'erase-multiple-odds':
        'This action will delete integrals, so only the first odd in each match remains.',
      failed: 'Lost',
      fix: 'FIX',
      'group-number': 'Group No.',
      id: 'Code',
      'max-pot-payout': 'Max pot. payout',
      'max-pot-win': 'Max pot. winning',
      'max-potential-bonus': 'Max. potential bonus',
      'min-max-bonus': 'Min/Max Bonus',
      'min-max-odd': 'Min/Max odd',
      'min-max-pot-win': 'Min/Max pot. win',
      'min-pot-win': 'Min pot. winning',
      'min-potential-bonus': 'Min. potential bonus',
      'min-winning-total': 'Min pot. winning',
      multiplier: 'Odd',
      'my-tickets': 'MY TICKETS',
      'no-slip-events': 'No slip events',
      'no-tickets': 'No tickets.',
      'number-of-combinations': 'Number of combinations:',
      'number-of-matches': 'Number of events:',
      'number-of-pairs': 'Nr. of pairs:',
      'number-of-tickets': 'Ticket number:',
      odd: 'Odd:',
      paid: 'Paid out',
      passed: 'Won',
      payin: 'Amount:',
      'payin-time': 'Time:',
      'payment-time': 'Payment time',
      payout: 'Payout:',
      'place-bet': 'PLACE BET',
      'possible-winning': 'Possible winning',
      'pot-winning-amount': 'Potential payout',
      'pot-winning-amount-short': 'Pot. payout',
      'potential-bonus': 'Potential bonus',
      'potential-gain': 'Potential winning',
      'quick-ticket-code': 'SHOP TICKET CODE',
      'quick-ticket-live':
        'It is not possible to get SHOP ticket with live matches',
      'quick-ticket-pay':
        'Shop ticket can be activated at any SBbet shop. The code is:',
      'quick-ticket-terms':
        'The betting conditions and restrictions will be checked upon placing the bet at SBbet shop.',
      'quick-tickets': 'SHOP TICKETS',
      regular: 'REGULAR',
      'regular-ticket': 'REGULAR TICKET',
      'remove-match': 'Remove match',
      'remove-odd': 'Remove odd',
      round: 'Round',
      'save-games': 'Save events',
      'share-ticket-success': 'Ticket link successfully copied',
      'sign-in-status': 'Sign in to see ticket status.',
      'slip-not-eligible-for-cashout': 'Betslip not eligible for cashout.',
      'slip-payout': 'Ticket payout',
      stake: 'Stake',
      'stake-per-combination': 'Stake per combination:',
      status: 'Status',
      'status-capitalize': 'Status:',
      'switch-ticket': 'Do you want to switch the ticket to regular?',
      system: 'SYSTEM',
      'system-bonus-calculation':
        'The calculation of the bonus on the system ticket will be performed after all events are completed.',
      ticket: 'BETSLIP',
      'ticket-date': 'Slip date',
      'ticket-details': 'BETSLIP DETAILS',
      'ticket-id': 'Ticket code',
      'ticket-id-search': 'Betslip ID:',
      'ticket-overview': 'Ticket overview',
      'ticket-status': 'Ticked status',
      'ticket-type': 'Slip type',
      tickets: 'TICKETS',
      'top-tickets': 'TOP TICKETS',
      'total-odd': 'Total odd:',
      unconfirmed: 'Open',
      win: 'Winning:',
      'winning-amount-caps': 'WINNING AMOUNT',
    },
    sports: {
      'americki fudbal': 'American football',
      atletika: 'Athletics',
      badminton: 'Badminton',
      bejzbol: 'Baseball',
      biciklizam: 'Cycling',
      boks: 'Boxing',
      efudbal: 'eFootball',
      esport: 'eSports',
      evrovizija: 'Eurovision',
      fudbal: 'Football',
      futsal: 'Futsal',
      golf: 'Golf',
      hokej: 'Hockey',
      košarka: 'Basketball',
      mma: 'MMA',
      odbojka: 'Voleyball',
      olimpijada: 'Olympics',
      oskar: 'Oscars',
      pikado: 'Pikado',
      ragbi: 'Rugby',
      'ragbi liga': 'Rugby',
      rukomet: 'Handball',
      snuker: 'Snooker',
      'stoni tenis': 'Table tennis',
      tenis: 'Tennis',
      trke: 'Race',
      'trke konja': 'Horse races',
      vaterpolo: 'Waterpolo',
      'virtuelni fudbal': 'Football',
      'zimski sportovi': 'Winter sports',
    },
    sportsPage: {
      '1d': '1d',
      '1h': '1h',
      '3d': '3d',
      '3h': '3h',
      all: 'ALL',
      antepost: 'ANTEPOST',
      competition: 'Competition',
      'configure-offer': 'Configure offer',
      event: 'Event',
      limit: 'Limit',
      live: 'LIVE',
      'live-match-tracker': 'Live Match tracker',
      'no-events': 'No events',
      'no-odds': 'There is no offer at the moment',
      odds: 'ODDS',
      player: 'PLAYER',
      'sort-by-competition': 'Sort by competition',
      'sort-by-time': 'Sort by time',
      special: 'SPECIALS',
      upcoming: 'UPCOMING',
    },
    userData: {
      address: 'Address',
      adress: 'Address',
      'bank-account-number': 'Bank Account Number',
      'bank-name': 'Bank Name',
      'betting-win-loss': 'Win/Loss Betting',
      'casino-and-virtuals-win-loss': 'Casino and virtual win/loss',
      'change-password': 'Password change',
      'changes-active-in-24-hrs': 'Your changes will be active for 24 hours',
      city: 'City',
      code: 'Code',
      'confirm-password': 'Confirm password',
      country: 'Country',
      'current-password': 'Current password',
      'current-spending': 'Current consumption',
      'daily-limits': 'Daily limits',
      'date-of-birth': 'Date of birth',
      deposit: 'Deposit',
      'document-id': 'Passport ID',
      documents: 'Documents',
      'driver-license': 'Driver license',
      'email-address': 'Email address',
      'email-adress': 'Email address',
      female: 'Female',
      'first-name': 'Name',
      gender: 'Gender',
      'i-have-jmbg':
        'I have Personal Identification Number (only for citizens of Montenegro)',
      jmbg: 'Personal Identification Number',
      'last-name': 'Last name',
      male: 'Male',
      'monthly-limits': 'Monthly limits',
      'new-limit-value-is': 'The new limit value is',
      'new-limits-take-effect-in': 'The new restrictions come into effect for',
      'new-password': 'New password',
      passport: 'Passport',
      password: 'Password',
      'pause-gambling-confirmation':
        'This will disable sports betting, casino and virtual until the selected date. Are you sure you want to continue?',
      'pause-gambling-until': 'Pausing betting until',
      'personal-data': 'Personal data',
      'personal-id': 'ID card',
      phone: 'Telephone',
      place: 'Place',
      'responsible-gambling': 'Responsible betting',
      save: 'Save',
      'street-and-number': 'Street and street number',
      telephone: 'Telephone',
      'user-details': 'Personal details',
      username: 'Username',
      'weekly-limits': 'Weekly limits',
    },
    userMarket: {
      cancel: 'Cancel',
      code: 'Code',
      doctor: 'Doctor',
      expert: 'Expert',
      items: 'Items',
      order: 'Order',
      'order-success': 'Successful order',
      place: 'Place',
      professor: 'Professor',
      'sbbet-points': 'SBBet points',
      'successfuly-canceled-order': 'Successfuly canceled order',
    },
    validations: {
      'amount-greater-than-available':
        'Entered amount is greater than available',
      'cannot-mix-group-events':
        'It is not possible to mix these odds types and markets on the same betslip.',
      'event-disabled': 'Betting is disabled on event: {{a}}.',
      'event-starts-in-less-than':
        'Event {{a}} starts in less than {{b}} seconds',
      'free-bet-error':
        'Free bet slip must contain minimum of {{minEvents}} events and total odd must be greater than {{minTotalOdd}}.',
      'free-bet-unvalidated':
        'Please verify your account so you can use promo account.',
      'live-odds-change': 'Please accept all changes',
      'max-bet-amount': 'Max bet amount is limited to {{a}}',
      'max-credit': 'Maximum credit amount is {{max}} €',
      'max-debit': 'Maximum debit amount is {{max}}',
      'max-number-of-combinations': 'Maximum number of combinations is {{a}}',
      'max-number-of-events': 'Maximum number of events is {{a}}',
      'max-number-of-same-events': 'Maximum number of integrals is {{a}}',
      'max-same-slips': 'You already placed {{a}} same betslips.',
      'min-amount-per-combination':
        'Minimal bet amount per combination is {{a}}',
      'min-credit': 'Minimum credit amount is {{min}} €',
      'min-debit': 'Minimum debit amount is {{min}}',
      'min-payment-for-number-of-matches':
        'Minimum bet amount is {{a}} when betting on {{b}} events or less.',
      'no-space': 'Space is not allowed',
      'odd-disabled': 'Odd {{a}} is disabled.',
      'odd-higher-than-one': 'Each odd must be greater than 1.',
      'only-letters-and-spaces-allowed': 'Only letters and spaces allowed',
      'only-numbers-allowed': 'Only numbers allowed',
      'repeat-password': 'Repeat the password',
      'validation-antepost-system':
        'It is not possible to combine more than one antepost on system betslip.',
      'validation-balance': "You don't have enough funds.",
      'validation-max-payment': 'Bet amount is higher than defined.',
      'validation-max-systems': 'Maximum number of systems is {{a}}.',
      'validation-max-win': 'Maximum potential winning is {{a}}',
      'validation-min-payment': 'Minimum bet amount is {{minAmount}} EUR.',
      'validation-mix-long-short-term':
        'It is not possible to combine antepost {{a}} and event {{b}} on the same betslip.',
      'validation-mixed-event-types':
        'Antepost events can only be played as a single.',
      'validation-mixed-markets':
        'Unable to bet on market {{a}} and market {{b}} on the same betslip.',
      'validation-not-validated': 'An unverified user cannot withdrawn funds.',
      'validation-only-singles':
        'Event {{a}} is not possible to combine with other events.',
      'validation-single-choice':
        'Event {{a}} cannot contain more than one odd type.',
    },
    verification: {
      'accept-terms': 'I accept the terms of use',
      'account-activated': 'Account has been successfully activated',
      'account-success':
        'Congratulations! You have successfully activated your account!',
      'agree-email-notif':
        'I want to receive information and promotions by email',
      'agree-sms-notif': 'I want to receive information and promotions by SMS',
      confirm: 'CONFIRM',
      'connection-online': 'Internet connection is established!',
      'data-saved': 'Data saved successfully',
      'deny-changes': 'You have successfully declined ticket change.',
      'deny-changes-timer':
        'Ticket changes were denied due to the expiration of the time for accepting the changes.',
      'email-verified-success':
        'You have successfully verified your email account',
      'enter-recovery-email':
        'Enter the email address used to create SBbet account',
      instruction:
        'Send an SMS on number 1818 in format: SbbIDnumberDepositAmount',
      'login-activation': 'Log in to use the application',
      'promo-code-activated':
        'You have successfully activated the promo code. You have received {{amount}} {{currency}} in your {{walletType}} account.',
      'promo-code-activated-multiple-wallets':
        'You have successfully activated the promo code. You have received {{amount}} {{currency}} in your free bet account and {{amount_fc}} {{currency}} in your free spins account.',
      'promo-code-already-activated': 'The promo code is already activated',
      'promo-code-not-for-existing-users': 'Promo code not for existing users',
      'promo-code-not-for-new-users':
        'The promo code is not intended for new users',
      'promo-code-used-up': 'Promo code already used up',
      'recovery-email-sent': 'We have sent you an email to your email address',
      'successfully-changed-password':
        'You have successfully changed your password',
      'ticket-approved':
        'Your ticket has been approved. Click to see the ticket.',
      'ticket-paid': 'Ticket was successfully paid.',
      'ticket-waiting-approval': 'Your ticket is awaiting approval.',
      'upload-documents': {
        'choose-document': 'Select a document',
        'document-type': 'Verification document type:',
        'first-page': 'Front side of document:',
        'second-page': 'Back side of document:',
        submit: 'Submit',
        title: 'Loading a document',
        'upload-first-page': 'Upload front side of document',
        'upload-second-page': 'Upload back side of document',
        'user-update-success': 'Upload successful',
      },
      'validation-18-year': 'You are not 18 years old.',
      'verification-complete': 'Account has been successfully verified.',
      'verification-in-progress': 'Verification in progress',
      'verification-modal': {
        activate: 'Account verification',
        desc: 'You can verify your account on your account page: My account > Account > Documents',
        'doc-uploaded': 'Verification in process',
        Name: 'Name',
        proceed: 'Continue without verification',
        validate: 'Account verification',
      },
    },
    wallet: {
      'activate-bonus-info': 'Activate a promo bonus of {{bonus}} EUR',
      'casino-bonus-info':
        'You need to spend another {{amountToUnlock}} EUR to unlock the casino bonus',
      'casino-to-unlock': 'Casino Bonus to unlock',
      'free-bet': 'Free bet',
      'free-bet-bonus': 'Free bet bonus',
      'free-bet-bonus-info':
        'You need to spend another {{amountToUnlock}} EUR to unlock the free bet',
      'free-bet-bonus-to-unlock': 'Free bet bonus to unlock',
      'free-bet-bonus-unlock-message':
        'You need to spend another {{amountToUnlock}} EUR to unlock the free bets bonus',
      'free-spin': 'Free spin',
      'free-spin-to-unlock': 'Free spin to unlock',
      'free-spin-unlock-message':
        'You need to spend another {{amountToUnlock}} EUR to unlock the  free spin',
      'games-ticket-payout-info': 'Do you want to payout this ticket',
      'payout-success': 'Payout made successfully.',
      'poker-balance': 'Poker balance',
      'promo-bill': 'Promo wallet',
      'promo-bonus': 'Promo Bonus',
      'promo-to-unlock': 'Promo to unlock',
      'reserved-funds': 'Reserved funds',
      'standard-account': 'Standard wallet',
      'standard-bill': 'Standard wallet',
      'submit-for-print-success': 'Successfully submitted for printing',
      'ticket-successfully-paid': 'The ticket has been successfully paid',
      'valid-to': 'Valid to {{validTo}}',
      'voucher-payout-info': 'Do you want to payout this VOUCHER',
      'voucher-print': 'PRINT VOUCHER',
      'voucher-print-confirm': 'Do you want to continue?',
      'voucher-print-info':
        'All funds on the Balance will be withdrawn and a VOUCHER of the same value will be printed.',
      wallet: 'Wallet',
      'wallet-locked': 'Wallet is locked',
      'wallets-refresh': 'Refresh wallet data',
    },
  },
};

export default en;
