/* React modules */
import { useEffect } from 'react';

/* Our modules */
import appService from 'app/app-service';
import overlayStore from 'libs/overlay-store';
import Button from 'components/Button';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

const AppIdle = () => {
  const { t } = useTranslation();

  const onClick = () => {
    appService.setup();
    overlayStore.closeModal();
  };

  // If Effect subscribes to something, the cleanup function should unsubscribe (https://react.dev/learn/synchronizing-with-effects#subscribing-to-events)
  useEffect(() => {
    function handlePreventEscape(event: KeyboardEvent) {
      if (event) {
        event.preventDefault();
      }

      if (event.key === 'Escape') {
        appService.setup();
      }
    }

    window.addEventListener('keyup', handlePreventEscape);

    return () => window.removeEventListener('keyup', handlePreventEscape);
  }, []);

  return (
    <div className="d-flex justify-center align-items py-16">
      <div>
        <h4 className="text-center px-5 mb-4">{t('globals.app-idle-msg')}</h4>
        <Button
          className="cookies-banner__btn"
          size="regular"
          bg="warning"
          onClick={onClick}
        >
          {t('globals.proceed')}
        </Button>
      </div>
    </div>
  );
};

export default AppIdle;
