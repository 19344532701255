/* React modules */
import { LegacyRef } from 'react';

/* Our modules */
import { TicketData } from 'modules/ticket/ticket.types';
import { TicketStatus } from 'modules/ticket/ticket.types';
import useStores from 'common/hooks/useStores';
import { Icon } from 'components';
import { formatDate, formatTime, toDate } from 'libs/datetime';
import './TicketMobile.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';

interface TicketMobileProps {
  ticket: TicketData;
  openTicket: (id: string | undefined) => void;
  quickTicket: boolean;
}

const TicketMobile = ({
  ticket,
  openTicket,
  quickTicket,
}: TicketMobileProps) => {
  const date = toDate(ticket.bettingTime?.seconds);
  const ticketDate = formatDate(date);
  const ticketTime = formatTime(date);

  const { t } = useTranslation();

  const STATUS_DISPLAY: any = {
    [TicketStatus.NOT_RESOLVED]: {
      label: t('slips.active'),
      color: 'orange-800',
    },
    [TicketStatus.PASSED]: {
      label: t('slips.passed'),
      color: 'green',
    },
    [TicketStatus.FAILED]: {
      label: t('slips.failed'),
      color: 'red',
    },
    [TicketStatus.DENIED]: {
      label: t('slips.denied'),
      color: 'red',
    },
    [TicketStatus.PAYED_OUT]: {
      label: t('slips.paid'),
      color: 'green',
    },
    [TicketStatus.APPROVING]: {
      label: t('slips.approving'),
      color: 'yellow',
    },
    [TicketStatus.MANUAL_CHANGED]: {
      label: t('slips.changed'),
      color: 'blue',
    },
    default: {
      label: '',
      bg: 'green',
    },
  };

  const ticketStatus = STATUS_DISPLAY[ticket.status] || STATUS_DISPLAY.default;

  return (
    <div className="user-ticket-mobile sb-text-small">
      <div className="bg-black-700">
        <div className="py-2 px-2 w-92 mx-auto d-flex align-items">
          <div className="w-97 d-flex align-items space-between">
            <div>
              <p>{t('slips.id')}:</p>
              {!quickTicket && (
                <p className="mt-1">{t('slips.payment-time')}:</p>
              )}
            </div>
            <div className="mr-3 text-right">
              <p>{quickTicket ? ticket.code : ticket.shortUuid}</p>
              {!quickTicket && (
                <p className="mt-1">
                  {ticketDate} {ticketTime}
                </p>
              )}
            </div>
          </div>
          <div className="w-3 d-flex justify-end">
            <Icon
              name="search"
              size="medium"
              onClick={() =>
                quickTicket
                  ? openTicket(ticket.code)
                  : openTicket(ticket.shortUuid)
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className="py-1 px-2 w-92 mx-auto d-flex align-items border-b">
          <div className="w-95">
            <div className="d-flex align-items space-between">
              <p>{t('slips.stake')}:</p>
              <p className="mt-1">{ticket.bettingAmount}</p>
            </div>
          </div>
        </div>
        <div className="py-1 px-2 w-92 mx-auto d-flex align-items border-b">
          <div className="w-95">
            <div className="d-flex align-items space-between">
              <p>{t('globals.account-type')}:</p>
              <p className="mt-1">{ticket.accountType || '/'}</p>
            </div>
          </div>
        </div>
        <div className="py-1 px-2 w-92 mx-auto d-flex align-items border-b">
          <div className="w-95">
            <div className="d-flex align-items space-between">
              <p>{t('slips.status').toUpperCase()}:</p>
              <p className={`mt-1 text-${ticketStatus.color}`}>
                {ticketStatus.label}
              </p>
            </div>
          </div>
        </div>
        <div className="py-1 px-2 w-92 mx-auto d-flex align-items">
          <div className="w-95">
            <div className="d-flex align-items space-between">
              <p>{t('slips.potential-gain')}:</p>
              <p className="mt-1">{ticket.maxWinningAmount} EUR</p>
            </div>
          </div>
        </div>
        <div className="py-3 bg-grey-800">{/* placeholder */}</div>
      </div>
    </div>
  );
};

const TicketsMobile = observer(({ loadMore, openTicket, quickCodes }: any) => {
  const { ticketsStore } = useStores();
  const { tickets, quickSlipCodes, ticketsPagination } = ticketsStore;

  const scrollableTicketsMobileRef = useInfiniteScroll({
    next: loadMore,
    hasMore: { down: ticketsPagination.hasNext },
  });

  return (
    <div style={{ height: '300px' }}>
      <div
        ref={scrollableTicketsMobileRef as LegacyRef<HTMLDivElement>}
        className="user-ticket-overview__body"
      >
        {!!tickets &&
          !quickCodes &&
          tickets.map((ticket: any, index: number) => (
            <TicketMobile
              key={`TicketsMobile-TicketMobile-1-${ticket.id || index}`}
              quickTicket={quickCodes}
              ticket={ticket}
              openTicket={openTicket}
            />
          ))}

        {!!quickSlipCodes &&
          quickCodes &&
          quickSlipCodes.map((quickTicket: any, index: number) => (
            <TicketMobile
              key={`TicketsMobile-TicketMobile-2-${quickTicket.id || index}`}
              quickTicket={quickCodes}
              ticket={quickTicket}
              openTicket={openTicket}
            />
          ))}
      </div>
    </div>
  );
});

export default TicketsMobile;
