/* React modules */
import { useCallback, useEffect, useState } from 'react';

/* Our modules */
import {
  QuickGame,
  QuickGamesError,
  QuickGamesErrors,
  QuickGamesOptions,
} from 'modules/quick-games/quick-games.types';
import useStores from 'common/hooks/useStores';
import QuickGamesService from 'modules/quick-games/quick-games.service';
import { Button, Sidebar } from 'components';
import Responsive from 'components/Responsive';
import { Devices } from 'components/Responsive/Responsive';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import SinglePromo from 'components/PromoBanner/SinglePromo/SinglePromo';
import SingleBanner from 'modules/gambling/ui/SingleBanner';
import Login from 'modules/auth/ui/Login';
import { httpBanner } from 'app/axios-config';
import useQuery from 'libs/useQuery';
import { logger } from 'libs/common-helpers';
import luckySixImage from 'images/lucky-six.jpg';
import luckyXImage from 'images/lucky-x.jpg';
import nextSixImage from 'images/next-six.jpg';
import greyhoundImage from 'images/greyhound-races.jpg';
import horseRacesImage from 'images/virtual-horse-races.jpg';
import aviatorImage from 'images/aviator.png';
import goalImage from 'images/goal.png';
import minesImage from 'images/mines.png';
import kenoImage from 'images/keno.png';
import diceImage from 'images/dice.png';
import hiloImage from 'images/hilo.png';
import miniRouletteImage from 'images/mini-roulette.png';
import plinkoImage from 'images/plinko.png';
import instantGreyhoundsImage from 'images/instant-greyhounds.jpg';
import './QuickGames.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const sevenGames = [
  {
    label: 'Lucky Six',
    value: QuickGamesOptions.LUCKY_SIX,
    img: luckySixImage,
  },
  { label: 'Aviator', value: QuickGamesOptions.AVIATOR, img: aviatorImage },
  { label: 'Mines', value: QuickGamesOptions.MINES, img: minesImage },
  { label: 'Goal', value: QuickGamesOptions.GOAL, img: goalImage },
  { label: 'Keno', value: QuickGamesOptions.KENO, img: kenoImage },
  {
    label: 'Mini Roulette',
    value: QuickGamesOptions.MINI_ROULETTE,
    img: miniRouletteImage,
  },
  {
    label: 'Greyhound',
    value: QuickGamesOptions.GREYHOUND,
    img: greyhoundImage,
  },
  { label: 'Plinko', value: QuickGamesOptions.PLINKO, img: plinkoImage },
  { label: 'Lucky X', value: QuickGamesOptions.LUCKY_X, img: luckyXImage },
  {
    label: 'Horse Races',
    value: QuickGamesOptions.HORSE_RACES,
    img: horseRacesImage,
  },
  { label: 'Hilo', value: QuickGamesOptions.HILO, img: hiloImage },
  { label: 'Next Six', value: QuickGamesOptions.NEXT_SIX, img: nextSixImage },
  // { label: 'Lightning Lucky Six', value: QuickGamesOptions.LIGHTNING, img: lightningImage },
  { label: 'Dice', value: QuickGamesOptions.DICE, img: diceImage },
];

const QuickGames = observer(({ mobile }: { mobile: boolean }) => {
  const { t, i18n } = useTranslation();
  const { authStore, userStore, gamblingStore, overlayStore } = useStores();
  const service = new QuickGamesService();
  const [gameUrl, setGameUrl] = useState('');
  const [gameOpen, setGameOpen] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [additionalGames, setAdditionalGames] = useState([]);
  const [games, setGames] = useState(sevenGames);
  let queryParams = useQuery();
  const navigate = useNavigate();
  const [quickGamesBanner, setquickGamesBanner] = useState<any>(null);
  const [quickGamesSideBanners, setquickGamesSideBanners] = useState<any>(null);

  useEffect(() => {
    const token = queryParams.get('token') || '';

    if (token) {
      authStore.setToken(token);
      userStore.getUserData();
      navigate('/quick-games-list-mobile');
    }
  }, []);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    const { data } = await httpBanner.get(`web/data.json`);
    if (data) {
      setquickGamesBanner(data.quick_games_main[0]);
      setquickGamesSideBanners(data.quick_games_side);
    }
  };

  const getQuickGames = useCallback(async () => {
    try {
      const { data } = await gamblingStore.getQuickGames();

      let winGoGame = null;
      let superHeli = null;
      let filteredGames = [];

      if ((data || []).length) {
        /*
        Check if there are 'Win&Go' (current 'id' in PRODUCTION environment is 16230) and
        'Super Heli' (current 'id' in PRODUCTION environment is 21519) quick games received from back-end
        */
        winGoGame = data.find((item: any) => item.id === 16230);
        superHeli = data.find((item: any) => item.id === 21519);

        // If they exist, remove them before concatenation, so they don't get added twice
        if (winGoGame) {
          filteredGames = data.filter(
            (item: any) => item.id !== 16230 && item.id !== 21519
          );
        }
      }

      // If 'Win&Go' quick game exists, add it as 1st one in the list of quick games
      let result = winGoGame
        ? [
            {
              label: winGoGame.game_name,
              value: winGoGame.id,
              img: winGoGame.image,
            },
            ...games,
            ...filteredGames.map((game: any) => ({
              label: game.game_name,
              value: game.id,
              img:
                game.game_name === 'Instant Greyhounds'
                  ? instantGreyhoundsImage
                  : game.image,
            })),
          ]
        : [
            ...games,
            ...data.map((game: any) => ({
              label: game.game_name,
              value: game.id,
              img:
                game.game_name === 'Instant Greyhounds'
                  ? instantGreyhoundsImage
                  : game.image,
            })),
          ];

      // If 'Super Heli' quick game exists, add it as 3rd one in the list of quick games
      result =
        (result || []).length >= 2 && superHeli
          ? [
              ...result.slice(0, 2),
              {
                label: superHeli.game_name,
                value: superHeli.id,
                img: superHeli.image,
              },
              ...result.slice(2),
            ]
          : result;

      setGames(result);
    } catch (exception: any) {
      logger('QuickGames -> getQuickGames -> exception', exception);
    }
  }, [gamblingStore, games, setGames]);

  useEffect(() => {
    getQuickGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuickGamesError = (error: QuickGamesError) => {
    if (error === QuickGamesErrors.USER_BLOCKED) {
      toast.error(t('errors.user-blocked-error'));
    } else {
      toast.error(t('errors.system-error'));
    }
  };

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  useEffect(() => {
    if (getParam('game')) {
      openGame(QuickGamesOptions.LUCKY_SIX);
    }
  }, []);

  useEffect(() => {
    if (gameOpen) {
      setLoader(true);
    }
  }, [gameOpen]);

  const fetchGameData = async (game: QuickGame, isDemo: boolean) => {
    const options = {
      lang: i18n.language,
      isDemo,
    };

    try {
      return await service.getGameUrl(game, options);
    } catch (exception: any) {
      handleQuickGamesError(exception?.data.detail);

      logger('QuickGames -> fetchGameData -> exception', exception);

      return null;
    }
  };

  const setGame = (game: QuickGame, url: string | null) => {
    if (url) {
      setGameUrl(url);
      setGameOpen(game);
    }
  };

  const openGame = useCallback(
    async (game: QuickGame) => {
      if (!authStore.isLoggedIn) {
        if (service.isLightning(game)) {
          overlayStore.openModal(<Login isModal />, { width: 'small' });
        } else {
          const url = await fetchGameData(game, true);
          setGame(game, url);
        }
      } else {
        const url = await fetchGameData(game, false);
        setGame(game, url);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [authStore.isLoggedIn, i18n]
  );

  useEffect(() => {
    if (gameOpen) {
      openGame(gameOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const openAdditionalGame = useCallback(
    async (gameId: number) => {
      try {
        const game_url = authStore.isLoggedIn
          ? await gamblingStore.getQuickGameUrl(gameId, authStore.token)
          : await gamblingStore.getTryQuickGameUrl(gameId);

        setGameUrl(game_url);
        setGameOpen(gameId);
      } catch (exception: any) {
        toast.error(t('errors.system-error'));

        logger('QuickGames -> openAdditionalGame -> exception', exception);
      }
    },
    [authStore.isLoggedIn, authStore.token, gamblingStore, t]
  );

  const isAdditional = (value: any) => {
    return additionalGames.some((game: any) => game.id === value);
  };

  const onGameSelect = (game: any) => {
    if (isAdditional(game)) {
      openAdditionalGame(game);
    } else {
      openGame(game);
    }
  };

  const closeGame = () => {
    setGameOpen(null);
    userStore.getUserData();
  };

  useEffect(() => {
    if (gameOpen) {
      onGameSelect(gameOpen);
    }
  }, [authStore.token]);

  const onIframeLoad = () => {
    setLoader(false);
    if (service.isSpribe(gameOpen as QuickGame)) return;

    if (window.innerWidth < 769) {
      // @ts-ignore
      iFrameResize({
        checkOrigin: false,
        autoResize: false,
        initCallback: function (iframe: HTMLIFrameElement) {
          if (iframe) {
            iframe.style.height = 'calc(100% - 60px)';
          }

          /*
          This is removed for now, as the same height for the iframe is already set
          and also to prevent memory leak because there is no appropriate removeEventListener call to do the cleanup.

          window.addEventListener("resize", function () {
            iframe.style.height = "calc(100% - 60px)";
          });
          */
        },
        messageCallback: function (data: any) {
          if (data.message && data.message.type) {
            switch (data.message.type) {
              case 'loginRequired':
                overlayStore.openModal(<Login isModal />, { width: 'small' });
                break;
            }
          }
        },
      });
    } else {
      // @ts-ignore
      iFrameResize({
        checkOrigin: false,
        heightCalculationMethod: 'lowestElement',
        messageCallback: function (data: any) {
          if (data.message && data.message.type) {
            switch (data.message.type) {
              case 'loginRequired':
                overlayStore.openModal(<Login isModal />, { width: 'small' });
                break;
            }
          }
        },
      });
    }
  };

  return (
    <div className="number-games">
      {gameOpen && loader && <Loader />}

      {!gameOpen && (
        <div className="d-flex w-100">
          <div
            className={
              !mobile
                ? 'number-games__content w-100'
                : 'number-games__content-mobile-app w-100'
            }
          >
            {quickGamesBanner && (
              <SingleBanner
                banner={quickGamesBanner}
                className="games-grid__banner"
              />
            )}

            <div className="number-games__container">
              {games.map((game: any) => (
                <div
                  key={`QuickGames-div-${game.label}`}
                  className="number-games__game"
                  onClick={() => onGameSelect(game.value)}
                >
                  <img src={game.img} alt={game.label} />
                  <p className="number-games__game--label">{game.label}</p>
                </div>
              ))}
            </div>

            <div style={{ width: '100%' }} className="justify-center">
              <Responsive display="block" fromDesktop={<Footer />} />
            </div>
          </div>

          <Responsive visibleFrom={Devices.LAPTOP}>
            <Sidebar borderLeft>
              <div className="user-profile__promo-sidebar">
                {quickGamesSideBanners?.map(
                  (banner: any, i: number) =>
                    banner && (
                      <SinglePromo
                        key={`QuickGames-SinglePromo-${i}`}
                        promo={banner}
                        isOpen={true}
                        sidebarBanner={true}
                      />
                    )
                )}
              </div>
            </Sidebar>
          </Responsive>
        </div>
      )}

      {gameOpen && (
        <div className="w-100">
          <div>
            <Button
              className="ml-8 my-3"
              size="medium"
              bg="ultra-black"
              onClick={closeGame}
            >
              {t('casino.back')}
            </Button>
          </div>
          <div className="number-games__content">
            <iframe
              title="number-games"
              id="seven-plugin"
              onLoad={() => onIframeLoad()}
              src={gameUrl}
              className={
                isAdditional(gameOpen) ||
                service.isSpribe(gameOpen) ||
                service.isLightning(gameOpen)
                  ? 'additional-game-iframe'
                  : ''
              }
              scrolling="no"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
});

export default QuickGames;
