/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import PaymentsApi from 'modules/payment/payment.api';
import { PaymentMethods, PaymentMethod } from 'modules/payment/payment.types';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { toast } from 'react-toastify';

class PaymentService {
  private api: PaymentsApi;

  constructor() {
    this.api = new PaymentsApi();
  }

  initDeposit = async (
    amount: number,
    method: PaymentMethod = PaymentMethods.MONRI
  ) => {
    try {
      if (method === PaymentMethods.MONRI) {
        const { data } = await this.api.initMonriDeposit(amount);

        return data;
      } else {
        logger(
          'PaymentService -> initDeposit -> Payment method not supported.'
        );

        return null;
      }
    } catch (exception: any) {
      logger('PaymentService -> initDeposit -> exception', exception);

      if ((exception || {}).data) {
        const { detail } = exception.data || {};

        toast.error(i18n.t(`errors.${detail}`));
      }
    }
  };

  getWithdrawalReservation = () => {
    return this.api.getWithdrawalReservation();
  };

  getBankWithdrawalReservation = () => {
    return this.api.getBankWithdrawalReservation();
  };

  submitWithdrawal = async (data: any) => {
    try {
      await this.api.createWithdrawal(data);

      return true;
    } catch (exception: any) {
      logger('PaymentService -> submitWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  submitBankWithdrawal = async (data: any) => {
    try {
      await this.api.createBankWithdrawal(data);
      return true;
    } catch (exception: any) {
      logger('PaymentService -> submitBankWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  cancelWithdrawal = async () => {
    try {
      await this.api.cancelWithdrawal();
      return true;
    } catch (exception: any) {
      logger('PaymentService -> cancelWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  cancelBankWithdrawal = async () => {
    try {
      const data = await this.api.cancelBankWithdrawal();

      return Promise.resolve(data);
    } catch (exception: any) {
      logger('PaymentService -> cancelBankWithdrawal -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  activatePromoCode = async (code: string) => {
    try {
      const { data } = await this.api.activatePromoCode(code);

      return data;
    } catch (exception: any) {
      logger('PaymentService -> activatePromoCode -> exception', exception);

      return Promise.reject(exception.data);
    }
  };

  checkPromoCode = async (code: string) => {
    return this.api.checkPromoCode(code);
  };

  getMinMaxWithdrawal(): Promise<any> {
    return this.api.getMinMaxWithdrawal();
  }
}

export default PaymentService;
