/* React modules */
import { LegacyRef } from 'react';

/* Our modules */
import { OrderStatus } from 'modules/user/market.types';
import useStores from 'common/hooks/useStores';
import { Icon } from 'components';
import SingleOrderModal from 'modules/user/ui/UserMarket/OrderHistory/SingleOrderModal/SingleOrderModal';
import { formatDate, toDate } from 'libs/datetime';
import { logger } from 'libs/common-helpers';
import './HistoryMobile.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';

const SingleOrder = observer(({ order, openSingleOrder }: any) => {
  const { t } = useTranslation();
  const { bettingPlacesStore } = useStores();
  const { bettingPlacesList } = bettingPlacesStore;

  const STATUS_DISPLAY: any = {
    [OrderStatus.PENDING]: {
      label: t('globals.pending'),
      color: 'orange-800',
    },
    [OrderStatus.SUCCESS]: {
      label: t('globals.success'),
      color: 'green',
    },
    [OrderStatus.DELIVERED]: {
      label: t('globals.delivered'),
      color: 'green',
    },
    [OrderStatus.APPROVED]: {
      label: t('globals.approved'),
      color: 'green',
    },
    [OrderStatus.CANCELED]: {
      label: t('globals.canceled'),
      color: 'red',
    },
    default: {
      label: '',
      bg: 'green',
    },
  };

  const orderStatus = STATUS_DISPLAY[order.status] || STATUS_DISPLAY.default;

  const open = (orderId: any) => {
    openSingleOrder(orderId);
  };

  const renderOrderDate = (value: any) => {
    return formatDate(toDate(value));
  };

  const renderAddress = (id: any) => {
    const orderAddress = bettingPlacesList?.find(
      (place: any) => place?.id === id
    );
    if (orderAddress) {
      return `${orderAddress?.address}, ${orderAddress?.municipalityName}`;
    }

    return '/';
  };

  return (
    <div className="market-history-mobile sb-text-small">
      <div className="bg-black-700">
        <div className="py-2 px-2 w-92 mx-auto d-flex align-items">
          <div className="w-97 d-flex align-items space-between">
            <div>
              <p>Status:</p>
              <p className="mt-1">{t('userMarket.code')}:</p>
            </div>
            <div className="mr-3 text-right">
              <p className={`mt-1 text-${orderStatus.color}`}>
                {orderStatus.label}
              </p>
              <p className="mt-1">{order.code}</p>
            </div>
          </div>
          <div className="w-3 d-flex justify-end">
            <Icon name="search" size="medium" onClick={() => open(order.id)} />
          </div>
        </div>
      </div>

      <div>
        <div className="py-1 px-2 w-92 mx-auto d-flex align-items border-b">
          <div className="w-95">
            <div className="d-flex align-items space-between">
              <p>{t('globals.date')}:</p>
              <p className="mt-1">{renderOrderDate(order.createdAt)}</p>
            </div>
          </div>
        </div>
        <div className="py-1 px-2 w-92 mx-auto d-flex align-items border-b">
          <div className="w-95">
            <div className="d-flex align-items space-between">
              <p>{t('userMarket.place')}:</p>
              <p className="mt-1">{renderAddress(order.bettingPlaceId)}</p>
            </div>
          </div>
        </div>
        <div className="py-3 bg-grey-800">{/* placeholder */}</div>
      </div>
    </div>
  );
});

const HistoryMobile = observer(({ loadMore, openOrder }: any) => {
  const { marketStore, overlayStore } = useStores();
  const { getOrders } = marketStore;

  const scrollableHistoryMobileRef = useInfiniteScroll({
    next: loadMore,
    hasMore: { down: true },
  });

  const openSingleOrder = async (orderId: any) => {
    try {
      await marketStore.fetchSingleOrder(orderId);

      overlayStore.openModal(<SingleOrderModal />, {
        width: 'large',
      });
    } catch (exception: any) {
      logger('HistoryMobile -> openSingleOrder -> exception', exception);

      return null;
    }
  };

  return (
    <div style={{ height: '300px' }}>
      <div
        ref={scrollableHistoryMobileRef as LegacyRef<HTMLDivElement>}
        className="market-history__body"
      >
        {getOrders &&
          getOrders.map((order: any) => (
            <SingleOrder
              key={`HistoryMobile-SingleOrder-${order.id}`}
              order={order}
              openSingleOrder={openSingleOrder}
            />
          ))}
      </div>
    </div>
  );
});

export default HistoryMobile;
