/* React modules */
import { useCallback, useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import {
  QuickGamesErrors,
  QuickGamesOptions,
  QuickGame,
} from 'modules/quick-games/quick-games.types';
import QuickGamesService from 'modules/quick-games/quick-games.service';
import { logger } from 'libs/common-helpers';
import './Aviator.scss';

/* 3rd Party modules */
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Aviator = observer(() => {
  const { authStore } = useStores();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const service = new QuickGamesService();
  const [gameUrl, setGameUrl] = useState<string | null>(null);

  const fetchGameData = async (game: QuickGame) => {
    const options = {
      lang: i18n.language,
      isDemo: !authStore.isLoggedIn,
    };

    try {
      return await service.getGameUrl(game, options);
    } catch (exception: any) {
      if (exception?.data?.detail === QuickGamesErrors.USER_BLOCKED) {
        toast.error(t('errors.user-blocked-error'));
        navigate('/');
      }

      logger('Aviator -> fetchGameData -> exception', exception);

      return null;
    }
  };

  const initGame = useCallback(async () => {
    try {
      const url = await fetchGameData(QuickGamesOptions.AVIATOR);

      setGameUrl(url);
    } catch (exception: any) {
      logger('Aviator -> initGame -> exception', exception);

      return null;
    }
  }, [fetchGameData]);

  useEffect(() => {
    initGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, authStore.isLoggedIn]);

  if (!gameUrl) return null;

  return (
    <div className="aviator">
      <iframe
        title="number-games"
        id="seven-plugin"
        src={gameUrl}
        scrolling="yes"
      ></iframe>
    </div>
  );
});

export default Aviator;
