/* React modules */
import { ChangeEvent, useEffect, useState, useMemo } from 'react';

/* Our modules */
import { GenderEnum, GenderType } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import { Input, Button, Icon, Select } from 'components';
import { SelectOption } from 'components/Select/Select';
import { formatDate, toDateObj } from 'libs/datetime';
import Validators from 'libs/validations';
import { countryList, montenegroCities } from 'libs/country-list';
import { logger } from 'libs/common-helpers';
import './AccountDetails.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export interface AccountDetailsProps {}

const DisplayInfo = ({
  title,
  subtitle,
}: {
  title: string | undefined;
  subtitle: string | null | undefined;
}) => (
  <div className="display-info">
    <div className="text-strong text-white">{title}</div>
    <div className="__value mt-1">{subtitle}</div>
  </div>
);

const validateField =
  (key: string, setErrors: (arg: any) => void, validations: Array<any>) =>
  (e?: any) => {
    const value = e.target ? e.target.value : e;
    if (validations) {
      const error = (validations as Array<any>).find(
        (v: any) => v && !v.validate(value)
      );
      if (error) {
        setErrors({ [key]: error.message });
      } else {
        setErrors({ [key]: null });
      }
    }
  };

const AccountDetails = () => {
  const { userStore } = useStores();
  const { user, updateUserData } = userStore;
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<any>('');
  const [country, setCountry] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const { t } = useTranslation();

  const fieldValidation = useMemo(
    () => ({
      country: [Validators.required],
      city: [Validators.required],
      address: [Validators.required],
      phone_number: [Validators.required, Validators.onlyNumbers],
    }),
    []
  );

  const updatePhone = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value.length > 32) return;
    validateField(
      'phone_number',
      setFieldError,
      fieldValidation.phone_number
    )(e);
    setPhone(e.target.value);
  };

  const updateCity = (e: any) => {
    if (e?.target?.value.length > 32) return;
    validateField('city', setFieldError, fieldValidation.city)(e);

    if (e.target) {
      setCity(e.target.value);
    } else {
      setCity(e);
    }
  };

  const updateAddress = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value.length > 32) return;
    validateField('address', setFieldError, fieldValidation.address)(e);
    setAddress(e.target.value);
  };

  const updateCountry = (country: any) => {
    setCountry(country);
  };

  const setFieldError = (fieldError: any) => {
    setErrors({ ...errors, ...fieldError });
  };

  useEffect(() => {
    if (user) {
      const userCity =
        user?.country === 'MNE'
          ? montenegroCities.find((c) => c.value === user?.city)
          : user?.city;
      setPhone(user?.phone_number);
      setCity(userCity);
      setAddress(user?.address || '');
      setCountry(countryOptions.find((c) => c.value === user?.country) || null);
    }
  }, [user]);

  const submit = async () => {
    try {
      const payload = {
        country: country.value,
        city: typeof city === 'string' ? city : city.value,
        address,
        phone_number: phone,
      };

      const success = await updateUserData(payload);

      if (success) {
        toast.success(t('verification.data-saved'));
      }
    } catch (exception: any) {
      if (exception?.detail === 'Unauthorized') {
        toast.error(t('errors.session-expired'));
      } else {
        toast.error(t('errors.data-error-reading'));
      }

      logger('AccountDetails -> submit -> exception', exception);
    }
  };

  const countryOptions: SelectOption[] = useMemo(() => countryList(t), [t]);

  const hasErrors = () => {
    const errorKeys = Object.keys(errors);
    return !!errorKeys.length && errorKeys.some((k) => !!errors[k]);
  };

  return (
    <div className="account-details">
      <div className="__wrapper">
        <div className="first-section">
          <DisplayInfo
            title={t('userData.first-name')}
            subtitle={user?.first_name}
          />
          <DisplayInfo
            title={t('userData.last-name')}
            subtitle={user?.last_name}
          />
        </div>
        <DisplayInfo title={t('userData.username')} subtitle={user?.username} />
        <DisplayInfo title="ID" subtitle={String(user?.id || '')} />
        <DisplayInfo
          title={t('userData.email-address')}
          subtitle={user?.email}
        />
        <DisplayInfo
          title={t('userData.date-of-birth')}
          subtitle={formatDate(toDateObj(user?.date_of_birth as string))}
        />
        <DisplayInfo
          title={t('userData.gender')}
          subtitle={GenderEnum[user?.gender as GenderType]}
        />
        <DisplayInfo title={t('userData.jmbg')} subtitle={user?.ssn} />

        <div className="display-info mb-2">
          <div className="text-strong text-white d-flex">
            {t('userData.country')}
            <Icon name="pen" size="extra-small" className="ml-2" />
          </div>
          <Select
            bg="grey"
            placeholder={t('userData.country')}
            options={countryOptions}
            value={country}
            error={errors.country}
            onChange={updateCountry}
            onBlur={validateField(
              'country',
              setFieldError,
              fieldValidation.country
            )}
            shape="squared"
          />
        </div>

        <div className="display-info mb-2">
          <div className="text-strong text-white d-flex">
            {t('userData.city')}
            <Icon name="pen" size="extra-small" className="ml-2" />
          </div>
          {country?.value !== 'MNE' && (
            <Input
              placeholder={t('userData.city')}
              className="mt-1 input"
              bg="light"
              name="city"
              value={typeof city !== 'string' ? city.label : city}
              onChange={updateCity}
              error={errors.city}
              onBlur={validateField(
                'city',
                setFieldError,
                fieldValidation.city
              )}
            />
          )}

          {country?.value === 'MNE' && (
            <Select
              bg="grey"
              placeholder={`${t('userData.city')}`}
              options={montenegroCities}
              value={city}
              error={errors.city}
              onChange={updateCity}
              onBlur={validateField(
                'city',
                setFieldError,
                fieldValidation.city
              )}
              shape="squared"
            />
          )}
        </div>

        <div className="display-info mb-2">
          <div className="text-strong text-white d-flex">
            {t('userData.street-and-number')}
            <Icon name="pen" size="extra-small" className="ml-2" />
          </div>
          <Input
            placeholder={t('userData.street-and-number')}
            className="mt-1 input"
            bg="light"
            name="address"
            value={address}
            onChange={updateAddress}
            error={errors.address}
            onBlur={validateField(
              'address',
              setFieldError,
              fieldValidation.address
            )}
          />
        </div>

        <div className="display-info mb-2">
          <div className="text-strong text-white d-flex">
            {t('userData.telephone')}
            <Icon name="pen" size="extra-small" className="ml-2" />
          </div>
          <Input
            placeholder={t('userData.telephone')}
            className="mt-1 input"
            bg="light"
            name="phone_number"
            value={phone}
            onChange={updatePhone}
            error={errors.phone_number}
            onBlur={validateField(
              'phone_number',
              setFieldError,
              fieldValidation.phone_number
            )}
          />
        </div>

        <div className="display-info">
          <Button disabled={hasErrors()} bg="success" onClick={submit}>
            {t('globals.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(AccountDetails);
