/* React modules */

/* Our modules */
import { getSocketUrl } from 'libs/urlBuilder';
import { parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */

const URL = 'ws/system/';

class SystemApi {
  private socket: WebSocket;

  constructor() {
    const socketUrl = getSocketUrl(URL);
    this.socket = new WebSocket(socketUrl);
  }

  connect(onMessage: (message: any) => void) {
    this.socket.addEventListener('message', ({ data }: any) => {
      const message = parseJSONString(data);

      if (message) {
        onMessage(message);
      }
    });
  }
}

export default SystemApi;
